@include media-breakpoint-up(lg) {
  .footer {
    background-color: $greyish-brown;
  }
  .footer-content {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .footer-logo {
    img {
      width: 140px;
      height: 60px;
      object-fit: contain;
    }
  }
  .footer-adresses {
    display: flex;
    justify-content: flex-start;
    .footer-adress {
      position: relative;
      height: 100%;
      margin: 0 30px 0 33px;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: -30px;
        top: 0;
        bottom: 0;
        width: 1px;
        background: #646464;
      }
      p {
        margin: 0;
        line-height: 20px;
        font-size: 12px;
        letter-spacing: 1px;
        color: $footer-text-color;
      }
      strong {
        font-weight: 500;
      }
      a {
        color: $footer-text-color;
        &:hover {
          text-decoration: none;
          color: rgba($white, 0.5);
        }
      }
      &:nth-of-type(1) {
        margin-left: 1px;
      }
    }
  }
  .footer-socail-media {
    padding-left: 7px;
    p {
      margin-bottom: 20px;
      line-height: 14px;
      font-size: 12px;
      letter-spacing: 1px;
      color: $footer-text-color;
    }
    ul {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        margin-right: 30px;
        a {
          display: inline-block;
          height: 24px;
          &:hover {
            img {
              opacity: 0.6;
            }
          }
        }
      }
    }
  }
  .footer-copy-right {
    background-color: $black-grey;
    p {
      margin: 10px 0;
      text-align: center;
      line-height: 12px;
      font-size: 10px;
      letter-spacing: 5px;
      color: $brown-grey;
    }
  }
}
