@include media-breakpoint-up(lg) {
  .filters-holder {
    opacity: 0;
    -webkit-animation: fadein 1s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s forwards; /* Firefox < 16 */
    -ms-animation: fadein 1s forwards; /* Internet Explorer */
    -o-animation: fadein 1s forwards; /* Opera < 12.1 */
    animation: fadein 1s forwards;

    -webkit-animation-delay: 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation-delay: 1s; /* Firefox < 16 */
    -ms-animation-delay: 1s; /* Internet Explorer */
    -o-animation-delay: 1s; /* Opera < 12.1 */
    animation-delay: 1s;
    .filters-wrapper {
      margin: 20px 0 0;
      box-shadow: 0 2px 10px 0 rgba(77, 121, 150, 0.13);

      > div {
        padding: 0;
      }
    }
  }
}
