@mixin headingOne {
  @include responsive-font-size(28px);
  margin-bottom: 0;
  line-height: calc(33 / 28);
  font-weight: $font-weight-normal;
}

@mixin headingTwo {
  @include responsive-font-size(24px);
  margin-bottom: 0;
  line-height: calc(28 / 28);
  font-weight: $font-weight-normal;
}

@mixin headingThree {
  margin-bottom: 0;
  font-size: 20px;
  line-height: 22px;
  font-weight: $font-weight-normal;
}

@mixin list-item {
  position: relative;
  padding-left: 22px;
  list-style: none;

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    top: 11px;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: $brownish-grey;
  }
}

@mixin list-number {
  position: relative;
  padding-left: 23px;
  list-style: none;
  counter-increment: li;

  &:before {
    content: counter(li) '. ';
    color: $brownish-grey;
    display: inline-block;
    position: absolute;
    left: 0;
  }
}

h1 {
  @include headingOne;
}

h2 {
  @include headingTwo;
}

h3 {
  @include headingThree;
}

p {
  font-size: 16px;
  line-height: 26px;
}

ul,
ol {
  padding: 0;

  li {
    font-size: 16px;
    line-height: 26px;
  }
}

//remove a link styles
h1,
h2,
h3,
h4,
h5 {
  a {
    &:hover {
      text-decoration: none;
    }
  }
}
