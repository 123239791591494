.toxicity{
    table{
        tr{
            td{
                tr{
                    td{
                        a{
                            display: inline-block;
                            color: $deep-sky-blue !important;
                            &:hover{
                                color: $cadet-blue !important;
                            }
                        }
                    }
                }
            }
        }
    }
}