.california-tab-content {
  .tab-content {
    .search-result-table {
      &.top-fifty-pesticides {
        .result-table {
          .result-table-row {
            &.titles {
              > div {
                background-color: $dark-navy-blue;
                > div {
                  width: 100px;
                  flex: 0 0 100px;
                  &:nth-of-type(1) {
                    width: 128px;
                    flex: 0 0 116px;
                  }
                  // &:nth-of-type(2) {
                  //   width: 146px;
                  //   flex: 0 0 146px;
                  // }
                  // &:nth-of-type(3) {
                  //   width: 113px;
                  //   flex: 0 0 113px;
                  // }
                  // &:nth-of-type(4) {
                  //   width: 87px;
                  //   flex: 0 0 87px;
                  // }
                  // &:nth-of-type(5) {
                  //   width: 116px;
                  //   flex: 0 0 116px;
                  // }
                  // &:nth-of-type(6) {
                  //   width: 115px;
                  //   flex: 0 0 115px;
                  // }
                  // &:nth-of-type(7) {
                  //   width: 103px;
                  //   flex: 0 0 103px;
                  // }
                }
              }
            }
            a {
              > div {
                width: 100px;
                flex: 0 0 100px;
                &:nth-of-type(1) {
                  width: 128px;
                  flex: 0 0 116px;
                }
                // &:nth-of-type(1) {
                //   width: 153px;
                //   flex: 0 0 153px;
                // }
                // &:nth-of-type(2) {
                //   width: 146px;
                //   flex: 0 0 146px;
                // }
                // &:nth-of-type(3) {
                //   width: 113px;
                //   flex: 0 0 113px;
                // }
                // &:nth-of-type(4) {
                //   width: 87px;
                //   flex: 0 0 87px;
                // }
                // &:nth-of-type(5) {
                //   width: 116px;
                //   flex: 0 0 116px;
                // }
                // &:nth-of-type(6) {
                //   width: 115px;
                //   flex: 0 0 115px;
                // }
                // &:nth-of-type(7) {
                //   width: 103px;
                //   flex: 0 0 103px;
                // }
              }
            }
          }
        }
      }
    }
  }
}
