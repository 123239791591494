.data-table {
  margin-bottom: 20px;
  border-radius: 4px;
  border: solid 1px $very-light-blue;
  background-color: $pale-grey;

  &.multiple-key-value-table {
    .data-table-content {
      .main-wrapper {
        width: 100%;
        border-bottom: 5px solid $very-light-blue;
        display: flex;
        flex-wrap: wrap;
        &:last-child {
          border-bottom: none;
        }
        .data-table-key-value {
          > div {
            flex: 0 0 50%;
            a {
              &:hover {
                text-decoration: none;
              }
            }                                  
          }
        }
      }
    }
  }

  .data-table-heading {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-bottom: solid 1px $very-light-blue;
    font-size: 15px;
    line-height: 18px;
    font-weight: $font-weight-medium;
    text-transform: uppercase;
    word-break: break-word;
    span {
      display: inline-block;
      min-width: 79px;
      // text-align: right;
      margin-left: 5px;
      line-height: 18px;
      font-size: 10px;
      color: $warm-grey;
      font-weight: $font-weight-normal;
      text-transform: none;
    }
  }

  .data-table-content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -1px;
  }

  .data-table-key-value {
    display: flex;
    flex: 0 0 50%;
    border-bottom: solid 1px $very-light-blue;
    > div {
      display: flex;
      padding: 10px 20px;
      font-size: 13px;
      line-height: 15px;
      color: $brownish-grey;
      word-break: break-word;
      &:nth-of-type(1) {
        position: relative;
        flex: 0 0 60%;
        padding-right: 10px;
        font-weight: $font-weight-medium;
        text-transform: capitalize;
        span {
          position: absolute;
          right: 0;
          top: 9px;
        }
        .kg-inner{
            text-transform: lowercase;
            position: initial;
            margin-right: 5px !important;
        }
      }
      &:nth-of-type(2) {
        flex: 0 0 41%;
      }
      a {
        color: $brownish-grey;
        &:hover {
          text-decoration: none;
          color: $cadet-blue;
        }
      }
    }
    &:nth-of-type(2n) {
      margin-left: -1px;
      border-left: solid 1px $very-light-blue;
    }
    &:nth-of-type(2n + 1) {
      border-right: solid 1px $very-light-blue;
    }
  }

  .data-table-link {
    padding: 6px 20px;
    a {
      line-height: 15px;
      font-size: 13px;
      color: $cadet-blue;
      text-decoration: none;
      &:hover {
        opacity: 0.7;
      }
    }
  }

  .view-more {
    flex: 0 0 100%;
    padding: 20px;
    text-align: center;
    a {
      color: $cadet-blue;
      text-transform: uppercase;
      font-weight: $font-weight-medium;
      text-decoration: none;
      position: relative;
      background: transparent;
      font-size: 13px;
      border: none;
      padding: 10px 0;
      text-align: center;
      &:hover {
        opacity: 0.6;
      }
      &::before {
        @include icons;
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        background-position: -93px -16px;
        left: -20px;
        top: 11px;
      }
    }
  }

  &.two-columns {
    .data-table-key-value {
      display: flex;
      flex: 0 0 50%;
      border-bottom: solid 1px $very-light-blue;
      > div {
        display: flex;
        flex: 0 0 50%;
        padding: 10px 20px;
        font-size: 13px;
        line-height: 15px;
        color: $brownish-grey;
        font-weight: $font-weight-normal;
      }
      &:nth-of-type(2n) {
        margin-left: -1px;
        border-left: solid 1px $very-light-blue;
      }
      &:nth-of-type(2n + 1) {
        border-right: solid 1px $very-light-blue;
      }
    }
  }

  &.one-column {
    .data-table-content {
      .data-table-key-value {
        display: block;
        flex: initial;
        border-right: none;
        width: 100%;
        > div {
          font-weight: $font-weight-medium;
          border-bottom: 1px solid $very-light-blue;
          &:last-child {
            border-bottom: none;
          }
          span {
            position: relative;
            margin-right: 10px;
            top: 0;
          }
          > div {
            font-weight: $font-weight-normal;
          }
        }

        h5,h6{
          font-size: 13px;
          line-height: 15px;
          color: $brownish-grey;
        }

        h5{
          width: 250px;
          font-weight: $font-weight-medium;
          text-transform: capitalize;
        }
        h6{
          font-weight: $font-weight-normal;
        }
        
      }
      .bee-toxin{
        .tooltip-holder{
          margin-right: 145px !important;
        }
      }
    }
  }
  &.with-search {
    display: flex;
    flex-wrap: wrap;
    .data-table-heading {
      flex: 0 0 50%;
      min-height: 38px;
      padding: 8.5px 19px;
      border-right: 1px solid $very-light-blue;
    }
    .button-holder {
      text-align: center;
      border-top: solid 1px $very-light-blue;
      width: 100%;
      .viewmore {
        color: $cadet-blue;
        text-transform: uppercase;
        font-weight: $font-weight-medium;
        text-decoration: none;
        position: relative;
        background: transparent;
        font-size: 13px;
        border: none;
        padding: 10px 0;
        &:hover {
          opacity: 0.6;
        }

        &::before {
          content: '';
          position: absolute;
          width: 14px;
          height: 14px;
          @include icons;
          background-position: -93px -16px;
          left: -20px;
          top: 12px;
        }
      }
    }
    .data-table-search {
      flex: 0 0 50%;
      // margin-left: -1px;
      input {
        padding: 10px 35px 10px 19px;
        border: none;
        border-radius: 0;
        background-color: $very-light-blue;
        width: 100%;
        height: 38px;
        margin-right: -1px;
        color: $cadet-blue;
        font-size: 13px;
        line-height: 15px;
        background-image: url('../../../../images/icons/search-small.svg');
        background-repeat: no-repeat;
        background-position: center right 20px;
        &::-webkit-input-placeholder {
          color: $cadet-blue;
          font-size: 13px;
          line-height: 15px;
        }
        &::-moz-placeholder {
          color: $cadet-blue;
          font-size: 13px;
          line-height: 15px;
        }
        &:-ms-input-placeholder {
          color: $cadet-blue;
          font-size: 13px;
          line-height: 15px;
        }
        &:-moz-placeholder {
          color: $cadet-blue;
          font-size: 13px;
          line-height: 15px;
        }
        &::-webkit-search-cancel-button {
          display: none;
          -webkit-appearance: none;
          font-size: 13px;
          line-height: 15px;
        }
      }
    }
    .data-table-content {
      flex: 0 0 100%;
      border-bottom: solid 1px $very-light-blue;
    }
    .data-table-key-value {
      flex: 0 0 50%;
      > div {
        flex: 0 0 100%;
        padding: 10px 19px;
        font-weight: $font-weight-normal;
      }
      &:nth-last-of-type(1) {
        margin-bottom: -1px;
      }
      &:nth-last-of-type(2) {
        margin-bottom: -1px;
      }
      &:nth-of-type(2n) {
        margin-left: -1px;
        border-left: solid 1px $very-light-blue;
      }
      &:nth-of-type(2n + 1) {
        border-right: solid 1px $very-light-blue;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .data-table {
    &.with-search {
      .data-table-heading {
        flex: 0 0 100%;
        height: auto;
        span {
          display: block;
        }
      }
    }
    &.one-column{
      .data-table-content .data-table-key-value h5{
        width: 160px;
      }
    }
  }
}


@include media-breakpoint-down(sm) {
  .data-table {
    .data-table-heading {
      text-align: center;
    }
    .data-table-key-value {
      flex: 0 0 100%;
      &:nth-of-type(2n) {
        margin-left: 0;
        border-left: none;
      }
      &:nth-of-type(2n + 1) {
        border-right: none;
      }
    }
    &.with-search {
      .data-table-heading {
        display: block;
      }
      .data-table-search {
        flex: 0 0 100%;
      }
    }
  }
}
