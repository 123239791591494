.post-blocks-search-container {
    * {
        animation: fadingIn 0.7s linear forwards;
        -webkit-animation: fadingIn 0.7s linear forwards;
    }
    margin-top: 14px;
    margin-bottom: 30px;
    .row {
        >div {
            .search {
                label {
                    display: none;
                }
                input[type='search'] {
                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
                    border: solid 1px #e1e1e1;
                    background-color: #fefdfb;
                    color: $warm-grey;
                    &::placeholder {
                        color: $warm-grey;
                        font-size: 16px;
                        font-weight: 300;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .post-blocks-search-container {
        margin-bottom: 50px;
    }
}

@include media-breakpoint-up(sm) {
    .post-blocks-search-container {
        .row {
            >div {
                .search {
                    input {
                        &[type='search'] {
                            padding: 20px 41px 19px 30px;
                        }
                    }
                }
            }
        }
    }
}