.chemical-selections {
  margin: 40px 0 30px 0;
  border-top: 1px solid $very-light-pink;
  border-bottom: 1px solid $very-light-pink;
  padding: 20px 0;
  .selection-title-wrapper {
    text-align: center;
    margin-top: -35px;
    h6 {
      display: inline-block;
      font-size: 12px;
      color: $brownish-grey;
      margin-bottom: 0;
      padding: 0 10px;
      background-color: $white;
    }
  }
  .selected-items {
    padding-top: 10px;
    .selected-item {
      position: relative;
      list-style: none;
      display: inline-block;
      margin-right: 5px;
      margin-bottom: 10px;
      border-radius: 5px;
      padding: 7px 20px 7px 10px;
      font-size: 13px;
      color: $black-grey;
      font-weight: $font-weight-medium;
      background-color: $very-light-pink;
      line-height: 1;
      cursor: pointer;
      &:hover {
        background-color: $cadet-blue;
        color: $white;
        transition: all 0.2s ease-in-out;
      }
      &:after {
        content: '';
        @include icons;
        background-position: -27px -60px;
        width: 9px;
        height: 9px;
        position: absolute;
        right: 5px;
        top: 9px;
      }
    }
  }
}
.button-holder {
  text-align: center;
  a {
    @include custom-form-button(11px 30px 11px 16px, $cadet-blue);
    letter-spacing: 0;
    &.disable {
      opacity: 0.5;
      pointer-events: none;
    }
    span {
      &:after {
        top: 4px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .chemical-selections {
    margin: 28px 0 42px 0;
    .selected-items {
      .selected-item {
        margin-right: 18px;
      }
    }
    .button-holder {
      margin-top: 13px;
    }
  }
}
