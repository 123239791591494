.california-tab-content {
  .tab-content {
    .search-result-table {
      &.top-fifty-crops {
        .result-table {
          .result-table-row {
            &.titles {
              > div {
                > div {
                  &:nth-of-type(1) {
                    width: 143px;
                    flex: 0 0 143px;
                  }
                  &:nth-of-type(2) {
                    width: 122px;
                    flex: 0 0 122px;
                  }
                  &:nth-of-type(3) {
                    width: 139px;
                    flex: 0 0 139px;
                  }
                  &:nth-of-type(4) {
                    width: 129px;
                    flex: 0 0 129px;
                  }
                  &:nth-of-type(5) {
                    width: 129px;
                    flex: 0 0 129px;
                  }
                  &:nth-of-type(6) {
                    width: 169px;
                    flex: 0 0 169px;
                  }
                }
              }
            }
            a {
              > div {
                &:nth-of-type(1) {
                  width: 143px;
                  flex: 0 0 143px;
                }
                &:nth-of-type(2) {
                  width: 122px;
                  flex: 0 0 122px;
                }
                &:nth-of-type(3) {
                  width: 139px;
                  flex: 0 0 139px;
                }
                &:nth-of-type(4) {
                  width: 129px;
                  flex: 0 0 129px;
                }
                &:nth-of-type(5) {
                  width: 129px;
                  flex: 0 0 129px;
                }
                &:nth-of-type(6) {
                  width: 163px;
                  flex: 0 0 163px;
                }
              }
            }
          }
        }
      }
    }
  }
}
