.ecotoxicity {
  table {
    &.environmental-toxicity-table,
    &.who-quality-table {
      tr {
        td {
          tr {
            td {
              width: 340px !important;
            }
          }
        }
      }
    }
  }
}
