.loading-item {
  $this: &;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #e9e9e9;
  &__image {
    > p {
      @extend %animated-background;
      width: 100%;
      height: 150px;
    }
  }
  &__content {
    > p {
      @extend %animated-background;
      height: 20px;
      width: 100%;
      margin: 8px 0;
    }
  }
}

%animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  //   height: 96px;
  position: relative;
}

// Animation
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@include media-breakpoint-up(md) {
  .loading-item {
    $this: &;
    &.large {
      @include clearfix;
      #{$this}__image {
        float: left;
        width: 40%;
        height: 220px;
        > p {
          height: 220px;
          margin-top: 8px;
        }
      }
      #{$this}__content {
        float: left;
        width: 60%;
        padding-left: 15px;
      }
    }
  }
}
