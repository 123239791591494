.text-block {
  padding: 15px 0;
  p {
    @include clearfix;
  }
  h1,
  h2,
  h3 {
    margin: 20px 0;
    + p,
    + ul,
    + ol {
      margin-top: 20px;
    }
  }
  > p,
  > ul,
  > ol {
    margin-bottom: 0;
    + p,
    + ul,
    + ol {
      margin-top: 15px;
    }
  }
  //> p + p {
  //  margin-top: 20px;
  //}
  > ul {
    margin-left: 30px;
    //padding: 0 0 0 2px;
    li {
      @include list-item;
      padding-left: 27px;
      // margin-bottom: 22px;
      &:last-of-type {
        margin-bottom: 0;
      }
      a {
        color: $primary-link-color;
        font-weight: $font-weight-medium;
        transition: all 0.3s ease-in-out;
        &:hover {
          text-decoration: none;
          opacity: 0.8;
        }
      }
    }
  }
  > ol {
    margin-left: 30px;
    //padding: 0 0 0 2px;
    li {
      @include list-number;
      padding-left: 27px;
      // margin-bottom: 22px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  > a,
  p > a {
    display: inline-block;
    color: $primary-link-color;
    font-weight: $font-weight-medium;
    transition: all 0.3s ease-in-out;
    &:hover {
      text-decoration: none;
      opacity: 0.8;
    }
  }
  //> a {
  //  margin-top: 40px;
  //}
  blockquote {
    position: relative;
    margin: 20px 0 30px;
    padding: 20px 20px 20px 65px;
    background-color: $cadet-blue;
    color: $white;
    line-height: 28px;
    font-family: $secondary-font;
    font-size: 18px;
    font-weight: $font-weight-medium;
    &:before {
      @include icons;
      content: '';
      position: absolute;
      top: 20px;
      left: 15px;
      display: inline-block;
      width: 37px;
      height: 37px;
      background-position: 0 -20px;
    }
    p {
      &:last-child {
        margin-bottom: 0;
      }
      color: $white;
      line-height: 28px;
      font-family: $secondary-font;
      font-size: 18px;
      font-weight: $font-weight-medium;
      letter-spacing: 0.1px;
    }
  }
  img {
    &.size-full {
      width: 100%;
      height: auto;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    &.alignright {
      width: auto !important;
      float: right;
      margin: 10px 0 14px 15px;
    }
    &.alignleft {
      width: auto !important;
      float: left;
      margin: 10px 15px 14px 0;
    }
  }
  iframe {
    display: flex;
    margin: 0 auto !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  > img {
    width: 100%;
  }
}

@include media-breakpoint-up(lg) {
  .text-block {
    padding: 28px 0;
    blockquote {
      margin: 50px 0 64px;
      padding: 40px 100px;
      &:before {
        left: 33px;
      }
    }
  }
  .detail-page-content {
    img {
      &.alignnone {
        display: flex;
        width: 80%;
        height: 80%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .text-block {
    iframe {
      width: auto;
      height: 100%;
    }
  }
}
