.home-donate {
  .text-block {
    padding: 34px 0;
    h2 {
      margin-bottom: 15px;
    }
    p {
      margin-bottom: 25px;
      margin-top: 17px;
      font-size: 14px;
      line-height: 1.5;
      color: $brown-grey;
      font-weight: $font-weight-normal;
    }
    a {
      @include primary-button;
    }
  }
}

// @include media-breakpoint-up(lg) {
//   .home-donate {
//     .text-block {
//       padding: 50px 0;
//     }
//   }
// }

@include media-breakpoint-down(lg) {
  .home-donate {
    .text-block {
      p {
        padding: 0 20px;
        br {
          display: none;
        }
      }
    }
  }
}
