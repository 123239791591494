.filter-search {
  .title {
    height: 40px;
    padding: 10px 20px;
    border: 1px solid $very-light-blue;
    border-radius: 4px 4px 0 0;
    background-color: $dark-navy-blue;
    line-height: 18px;
    font-size: 15px;
    font-weight: $font-weight-medium;
    color: $white;
    text-transform: uppercase;
    border-bottom: 0;
  }
  ul.filter {
    height: 224px;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0 0 4px 4px;
    border-left: 1px solid $very-light-blue;
    border-right: 1px solid $very-light-blue;
    border-bottom: 1px solid $very-light-blue;
    li {
      list-style: none;
      line-height: 15px;
      font-size: 13px;
      color: $brownish-grey;
      background: $white;
      &.item-search {
        background-color: transparent !important;
        padding: 10px 20px;
        padding-bottom: 10px !important;
        &:after {
          content: none;
        }
        input {
          border-radius: 4px;
          border: solid 1px $very-light-blue;
          padding: 10px 15px;
          width: 100%;
          font-size: 13px;
          height: 35px;
          background-color: $medium-grey;
          color: $black-grey;
          &::-webkit-input-placeholder {
            color: $warm-grey;
          }
          &::-moz-placeholder {
            color: $warm-grey;
          }
          &:-ms-input-placeholder {
            color: $warm-grey;
          }
          &:-moz-placeholder {
            color: $warm-grey;
          }
          &::-webkit-search-cancel-button {
            display: none;
            -webkit-appearance: none;
          }
        }
      }
      cursor: pointer;
      &:not(:last-of-type) {
        border-bottom: solid 1px $very-light-blue;
      }
    }
    > li {
      position: relative;
      padding: 20px;
      transition: all 0.15s ease-in-out;
      //height: 55px;
      &:after {
        @include icons;
        content: '';
        position: absolute;
        right: 20px;
        top: 26px;
        display: inline-block;
        width: 8px;
        height: 4px;
        background-position: -165px -26px;
      }
      > ul {
        margin: 20px -35px -1px -20px;
        background-color: $very-light-blue;
        border-top: 1px solid rgba(77, 121, 150, 0.13);
        > li {
          padding: 10px 20px;
          line-height: 15px;
          font-size: 13px;
          color: $brownish-grey;
          background-color: $very-light-blue;
          border-bottom: 1px solid rgba(77, 121, 150, 0.13) !important;
          transition: background-color 0.15s ease-in-out;
          &.selected {
            position: relative;
            &:after {
              @include icons;
              content: '';
              position: absolute;
              right: 33px;
              top: 14px;
              display: inline-block;
              width: 10px;
              height: 7px;
              background-position: -165px -38px;
            }
          }
          &:hover {
            margin-left: -1px;
            // margin-right: -1px;
            background-color: $cadet-blue;
            color: $white;
            border-left: 1px solid #4d7996;
            border-right: 1px solid #4d7996;
            &.selected {
              position: relative;
              &:after {
                background-position: -165px -47px;
              }
            }
          }
        }
      }
      &.collapsed {
        > ul {
          display: none;
        }
        &:after {
          background-position: -165px -32px;
        }
        &.selected {
          background: $cadet-blue;
          color: $white;
          &:after {
            width: 10px;
            height: 7px;
            background-position: -150px -44px;
          }
        }
      }
      &:not(.collapsed) {
        // margin-left: -1px;
        // margin-right: -1px;
        padding-bottom: 0;
        background: $cadet-blue;
        color: $white;
        &.selected {
          // background-color: $very-light-blue;
          // color: $brownish-grey;
          &:after {
            // width: 10px;
            // height: 7px;
            // background-position: -150px -44px;
          }
        }
      }
      &:hover.collapsed:not(.selected) {
        background-color: $cadet-blue;
        color: $white;
        // padding-bottom: 21px;
        // border-left-color: $cadet-blue;
        // border-right-color: $cadet-blue;
        &:after {
          background-position: -150px -44px;
        }
      }
    }
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px $very-light-blue;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #b7c4d2;
      outline: 1px solid $very-light-blue;
    }
  }
}
