.chemical-right-filters-wrapper {
  &.disable {
    position: relative;
    pointer-events: none;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}
.chemical-right-filters {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid $very-light-blue;
  .chemical-toxicity-filter,
  .chemical-regulatory-filter {
    flex: 0 0 45%;
    .title {
      height: 38px;
      padding: 10px 20px;
      border: 1px solid $very-light-blue;
      border-bottom: none;
      border-radius: 4px 4px 0 0;
      background-color: $dark-navy-blue;
      line-height: 18px;
      font-size: 15px;
      font-weight: $font-weight-medium;
      color: $white;
      text-transform: uppercase;
    }
  }
  .target-pest {
    margin-right: 10px;
  }
  > p {
    flex: 0 0 10%;
    text-align: center;
    line-height: 38px;
    font-size: 10px;
    font-weight: $font-weight-bold;
    color: $cadet-blue;
    text-transform: uppercase;
  }
  ul.filter {
    height: 227px;
    background: $white;
    border-bottom: 1px solid $very-light-blue;
    border-left: 1px solid $very-light-blue;
    border-right: 1px solid $very-light-blue;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0 0 4px 4px;
    li {
      list-style: none;
      border-bottom: solid 1px $very-light-blue;
      line-height: 15px;
      font-size: 13px;
      color: $brownish-grey;
      background: $white;
      cursor: pointer;
    }
    > li {
      position: relative;
      padding: 15px 35px 15px 20px;
      transition: all 0.15s ease-in-out;
      &:after {
        @include icons;
        content: '';
        position: absolute;
        right: 17px;
        top: 15px;
        display: inline-block;
        width: 15px;
        height: 15px;
        border-width: 3px 3px 4px 4px;
        border-color: $cadet-blue;
        border-style: solid;
        background-position: -182px -43px;
        background-color: $cadet-blue;
      }
      > ul {
        margin: 15px -35px -1px -20px;
        background-color: $very-light-blue;
        border-top: 1px solid rgba(77, 121, 150, 0.13);
        > li {
          position: relative;
          padding: 10px 20px 10px 40px;
          line-height: 15px;
          font-size: 13px;
          color: $brownish-grey;
          background-color: $very-light-blue;
          border-bottom: 1px solid rgba(77, 121, 150, 0.13);
          transition: background-color 0.15s ease-in-out;
          &:after {
            // @include icons;
            content: '';
            position: absolute;
            left: 18px;
            top: 10px;
            display: inline-block;
            width: 15px;
            height: 15px;
            border-radius: 2px;
            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.11);
            // border-width: 3px 3px 4px 4px;
            // border-color: $cadet-blue;
            // border-style: solid;
            // background-position: -182px -43px;
            background-color: $white;
          }
          &.selected {
            position: relative;
            &:after {
              @include icons;
              content: '';
              border-width: 3px 3px 4px 4px;
              border-color: $cadet-blue;
              border-style: solid;
              background-position: -165px -47px;
              background-color: $cadet-blue;
            }
          }
          &:hover {
            // margin-left: -1px;
            // margin-right: -1px;
            background-color: $cadet-blue;
            color: $white;
            &.selected {
              position: relative;
              &:after {
                background-position: -165px -47px;
              }
            }
          }
        }
      }
      &.collapsed {
        > ul {
          display: none;
        }
        &:after {
          // background-position: -182px -43px;
        }
        &.selected {
          background-color: $cadet-blue;
          color: $white;
          &:after {
            // width: 10px;
            // height: 7px;
            // background-position: -182px -43px;
          }
        }
      }
      &:not(.collapsed) {
        // margin-left: -1px;
        // margin-right: -1px;
        padding-bottom: 0;
        background: $cadet-blue;
        color: $white;
        &:after {
          width: 15px;
          height: 15px;
          border: 1px solid $cadet-blue;
          background-position: -180px -26px;
        }
        &.selected {
          // background-color: $very-light-blue;
          // color: $brownish-grey;
          &:after {
            // width: 10px;
            // height: 7px;
            // background-position: -180px -26px;
          }
        }
      }
      &:hover.collapsed:not(.selected) {
        background-color: $cadet-blue;
        color: $white;
      }
    }
    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px $very-light-blue;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b7c4d2;
      outline: 1px solid $very-light-blue;
    }
  }
}

@include media-breakpoint-up(xl) {
  .chemical-right-filters-wrapper {
    padding-left: 0;
  }
  .chemical-right-filters {
    .chemical-toxicity-filter,
    .chemical-regulatory-filter {
      flex: 0 0 262px;
    }
    > p {
      flex: 0 0 20px;
    }
  }
}

@include media-breakpoint-down(md) {
  .chemical-right-filters-wrapper {
    padding-left: 15px;
  }
  .chemical-right-filters {
    flex-wrap: wrap;
    margin-top: -1px;
    .chemical-toxicity-filter,
    .chemical-regulatory-filter {
      flex: 0 0 100%;
    }

    .chemical-toxicity-filter {
      margin-bottom: 38px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .chemical-right-filters {
    .chemical-toxicity-filter {
      margin-bottom: 20px;
    }
  }
}
