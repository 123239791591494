.map-zoom-wrapper {
  position: relative;
  .map-zoom {
    position: absolute;
    z-index: 1;
    top: 450px;
    right: 10px;
    width: 37px;
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #cccccc;
    background-color: #ffffff;
    button {
      width: 35px;
      height: 34px;
      line-height: 34px;
      border: none;
      background: transparent;
      font-size: 27px;
      color: $warm-grey;
      &[disabled] {
        opacity: 0.2;
      }
      &:nth-of-type(2) {
        font-size: 38px;
        line-height: 34px;
      }
    }
    &::after {
      position: absolute;
      top: 36px;
      left: 5px;
      content: '';
      display: inline-block;
      height: 1px;
      width: 25px;
      background: $very-light-pink;
    }
  }
}

@include media-breakpoint-down(sm) {
  .map-zoom-wrapper {
    .map-zoom {
      top: 330px;
    }
  }
}
