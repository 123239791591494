.post-blocks-container {
  * {
    animation: fadingInFast 0.7s linear forwards;
    -webkit-animation: fadingInFast 0.7s linear forwards;
  }
  .row {
    > div {
      margin-bottom: 29px;
    }
  }
}
