.tab-main-content {
  padding-bottom: 30px;
  .tab-main-wrapper {
    opacity: 0;
    -webkit-animation: fadein 1s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s forwards; /* Firefox < 16 */
    -ms-animation: fadein 1s forwards; /* Internet Explorer */
    -o-animation: fadein 1s forwards; /* Opera < 12.1 */
    animation: fadein 1s forwards;

    -webkit-animation-delay: 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation-delay: 2s; /* Firefox < 16 */
    -ms-animation-delay: 2s; /* Internet Explorer */
    -o-animation-delay: 2s; /* Opera < 12.1 */
    animation-delay: 2s;
  }
  .tab-content {
    padding: 20px;
    box-shadow: 0 2px 10px 0 rgba(77, 121, 150, 0.13);
    border: solid 1px $very-light-blue;
    margin-bottom: 30px;
    table {
      background-color: $pale-grey;
      border-radius: 4px;
      min-width: 600px;
      &.table-with-form {
        tr {
          td {
            &.form-container {
              background-color: $very-light-blue;
              form {
                input {
                  border: none;
                  background-color: transparent;
                  width: 100%;
                  background-image: url('../../../../images/icons/search-small.svg');
                  background-repeat: no-repeat;
                  background-position: center right;
                  &::-webkit-input-placeholder {
                    color: $cadet-blue;
                  }
                  &::-moz-placeholder {
                    color: $cadet-blue;
                  }
                  &:-ms-input-placeholder {
                    color: $cadet-blue;
                  }
                  &:-moz-placeholder {
                    color: $cadet-blue;
                  }
                  &::-webkit-search-cancel-button {
                    display: none;
                    -webkit-appearance: none;
                  }
                }
              }
            }
          }
        }
      }
      tr {
        > td {
          border: solid 1px $very-light-blue;
          padding: 11.5px 20px 9px 18px;
          font-size: 13px;
          color: $brownish-grey;
          width: 415px;
          line-height: 15px;
          // table{
          //   min-width: 400px;
          // }
          i {
            &.tooltip-icon {
              width: 23px;
              height: 12px;
              display: inline-block;
              &::after {
                content: '';
                position: absolute;
                @include icons;
                background-position: -111px -42px;
                width: 14px;
                height: 14px;
                top: 1px;
                margin-left: 5px;
              }
            }
          }
          span {
            &.count {
              font-size: 10px;
              color: $warm-grey;
              margin-left: 5px;
            }
          }
          tr {
            td {
              font-size: 13px;
              color: $brownish-grey;
              border: none;
              padding: 0;
              letter-spacing: normal;
              line-height: normal;
              position: relative;

              &:nth-of-type(1) {
                width: 67.5%;
                padding-right: 20px;
                font-weight: $font-weight-normal;
              }
              &:nth-of-type(2) {
                padding: 1px 0 0 2px;
              }
              span {
                top: 0;
                position: absolute;
                right: 0;
                margin-right: 20px;
              }
            }
          }
          p {
            font-size: 13px;
            color: $brownish-grey;
            font-weight: $font-weight-normal;
            margin-bottom: 0;
          }
          a {
            color: $cadet-blue;
            text-decoration: none;
            &:hover {
              color: $dark-navy-blue;
            }
          }

          strong {
            font-weight: $font-weight-medium;
            margin-right: 5px;
          }

          &.heading {
            font-size: 15px;
            text-transform: uppercase;
            font-weight: $font-weight-medium;
          }
          &.view-more {
            text-align: center;

            a {
              color: $cadet-blue;
              text-transform: uppercase;
              font-weight: $font-weight-medium;
              text-decoration: none;
              position: relative;

              &:hover {
                opacity: 0.6;
              }

              &::before {
                content: '';
                position: absolute;
                width: 14px;
                height: 14px;
                @include icons;
                background-position: -93px -16px;
                left: -20px;
                top: 0;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .tab-main-content {
    padding-bottom: 70px;
    .tab-content {
      margin-bottom: 0;
    }

    .upper-container {
      display: flex;
      .button-container {
        margin-left: auto;
        margin-top: 14px;
        a {
          width: auto;
          &.red-btn {
            margin-left: 12px;
          }
        }
      }
    }
    .tab-content {
      padding: 28px 20px 10px 20px;
      table {
        min-width: auto;
        tr {
          td {
            table {
              min-width: auto;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .tab-main-content {
    .tab-content {
      table {
        min-width: auto;
        tr {
          > td {
            width: auto;
            .symptoms-table {
              width: 800px;
            }
          }
        }
      }
    }
  }
}
