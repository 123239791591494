.nav-tabs {
  clear: both;
  border-bottom: none;
  li {
    margin-right: 0;
    margin-bottom: 5px;
    &.nav-item {
      min-width: 100%;
      display: inline-block;
      text-align: center;
      margin-bottom: 5px;
      a {
        padding: 10px 20px;
        color: $white;
        background-color: $cadet-blue;
        border-radius: 4px;
        box-shadow: 0 2px 10px 0 rgba(77, 121, 150, 0.08);
        line-height: 1;
        text-transform: uppercase;
        font-size: 14px;
        font-family: $primary-font;
        cursor: pointer;
        &.active {
          font-size: 14px;
          letter-spacing: 1px;
          background-color: $white;
          color: $cadet-blue;
          border: 1px solid $very-light-blue;
          font-weight: $font-weight-medium;
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .nav-tabs {
    justify-content: center;
    li {
      &.nav-item {
        min-width: 45%;
        &:nth-of-type(odd) {
          // margin-right: 5px;
        }
      }
    }
  }
}

@include media-breakpoint-between(sm, md) {
  .nav-tabs {
    justify-content: center;
    li {
      &.nav-item {
        min-width: 45%;
        margin: 5px 10px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .nav-tabs {
    justify-content: initial;
    border: none;
    margin-bottom: 0;
    li {
      margin-bottom: 0;
      &.nav-item {
        align-items: flex-end;
        display: flex;
        min-width: initial;
        margin-bottom: 0;
        a {
          position: relative;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          margin-right: 1px;
          padding: 11px 19px 10px 20px;
          letter-spacing: 1px;
          &:hover {
            border-color: transparent;
            background-color: $white;
            color: $cadet-blue;
            &:before {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              top: -2px;
              left: 0;
              background-color: $cadet-blue;
            }
          }
          &.active {
            box-shadow: 0 2px 10px 0 rgba(77, 121, 150, 0.13);
            border-left: solid 1px $very-light-blue;
            border-right: solid 1px $very-light-blue;
            border-top: solid 1px $very-light-blue;
            border-bottom: none;
            border-radius: 0;
            padding: 19px 22px 15px 24px;
            font-size: 16px;

            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 4px;
              background-color: $white;
              height: 21px;
              background-color: #ffffff;
              left: 0;
              bottom: -10px;
            }
            &:before {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              top: -2px;
              left: 0;
              background-color: $cadet-blue;
            }
          }
        }
      }
    }
  }
}
