.search-result-table {
  margin-top: 35px;

  .result-table {
    border-bottom: 1px solid $very-light-blue;
    .result-table-row {
      > a,
      > div {
        position: relative;
        display: flex;
        flex: 0 0 100%;
        flex-wrap: wrap;
        > div {
          position: relative;
          padding: 10px;
          flex: 0 0 16.5%;
          word-break: break-word;

          &:nth-of-type(1) {
            flex: 0 0 34%;
            position: relative;
            padding: 15px 20px;
            color: $deep-sky-blue;
            font-size: 13px;
            line-height: 15px;
            font-weight: $font-weight-normal;
            border-right: 1px solid $very-light-blue;
            color: $deep-sky-blue;
            font-size: 13px;
            line-height: 15px;
            font-weight: $font-weight-normal;
            &:hover {
              text-decoration: none;
            }

            h3 {
              margin: 0 0 2px;
              line-height: 15px;
              font-size: 13px;
              color: $deep-sky-blue;
              font-weight: $font-weight-normal;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              word-break: break-all;
            }
            h4 {
              margin: 0;
              line-height: 12px;
              font-size: 10px;
              color: $warm-grey;
              font-weight: $font-weight-normal;
            }
          }
          &:not(:first-of-type) {
            width: 200px;
            padding: 15px 20px;
            color: $brownish-grey;
            font-size: 13px;
            line-height: 15px;
            font-weight: $font-weight-normal;
            &:not(:last-of-type) {
              margin-left: -1px;
              border-left: 1px solid $very-light-blue;
              border-right: 1px solid $very-light-blue;
            }
          }
          &.popup-col-4 {
            width: calc(100% / 4);
            flex: 0 0 calc(100% / 4);
          }
          &.popup-col-5 {
            width: calc(100% / 5);
            flex: 0 0 calc(100% / 5);
          }
          &.popup-col-6 {
            width: calc(100% / 6);
            flex: 0 0 calc(100% / 6);
          }
          &.popup-col-7 {
            width: calc(100% / 7);
            flex: 0 0 calc(100% / 7);
          }
          &.popup-col-8 {
            width: calc(100% / 8);
            flex: 0 0 calc(100% / 8);
          }
          &.popup-col-9 {
            width: calc(100% / 9);
            flex: 0 0 calc(100% / 9);
          }
        }
      }
      &:not(.titles) {
        > a,
        > div {
          &:hover {
            z-index: 1;
            -webkit-box-shadow: 0 0 10px rgba(77, 121, 150, 0.25);
            box-shadow: 0 0 10px rgba(77, 121, 150, 0.25);
            // box-shadow: 0 0 15px rgba(77, 121, 150, 0.25);
            text-decoration: none;
            transition: all 0.3s ease-in-out;
            &:after {
              z-index: 1;
              content: '';
              position: absolute;
              right: 0;
              bottom: 0;
              width: 100%;
              height: 1px;
              background-color: rgba(77, 121, 150, 0.1);
            }
          }
        }
      }
      &:nth-of-type(odd) {
        background-color: $pale-grey;
      }
      &:nth-of-type(even) {
        background-color: $white;
      }
      &.titles {
        > div > div {
          position: relative;
          padding: 16px 20px;
          background: $dark-navy-blue;
          color: $white;
          font-size: 13px;
          line-height: 15px;
          text-transform: uppercase;
          font-weight: $font-weight-medium;
          border-color: transparent !important;
          cursor: pointer;
          word-break: break-word;
          &:not(:first-of-type) {
            width: 200px;
          }
          &:after {
            @include icons;
            content: '';
            display: inline-block;
            width: 7px;
            height: 8px;
            position: absolute;
            right: 20px;
            top: 20px;
            background-position: -47px -46px;
          }
        }
      }
    }
  }

  //table scroll__
  .result-table-wrapper-one {
    overflow-x: scroll;
    overflow-y: hidden;

    .result-table-one {
      width: 5120px;
      height: 100px;
    }
  }
  .result-table-wrapper-two {
    .result-table {
      height: 60vh;
    }
  }

  &.chemical-advanced {
    .result-table {
      overflow-y: auto;
      .result-table-row {
        > a,
        > div {
          flex: auto;
          flex-wrap: nowrap;
          > div {
            &.odd {
              background-color: $pale-grey;
            }
            &.even {
              background-color: $white;
            }
            &.odd,
            &.even {
              position: relative;
              &:before {
                display: none;
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                width: 15px;
                left: -15px;
                border-right: 1px solid $very-light-blue;
              }
              &:after {
                display: none;
                z-index: 1;
                content: '';
                position: absolute;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                background-color: rgba(77, 121, 150, 0.1);
              }
            }
            &.odd {
              &:before {
                background-color: $pale-grey;
              }
            }
            &.even {
              &:before {
                background-color: $white;
              }
            }
          }
          &:hover {
            box-shadow: 0 0 15px 0 rgba(77, 121, 150, 0.25);
            > div {
              transition: all 0.3s ease-in-out;
              &.odd,
              &.even {
                box-shadow: 0 0 15px 0 rgba(77, 121, 150, 0.25);
                &:after,
                &:before {
                  display: block;
                }
              }
            }
          }
        }
      }
    }

    // .result-table {
    //     overflow-y: auto;
    //     .result-table-row {
    //       @include clearfix;
    //       width: 1500px;
    //       > a,
    //       > div {
    //         display: block;
    //         width: 1500px;
    //         > div {
    //           display: inline-block;
    //           float: left;
    //           width: 100px;
    //           &:first-of-type {
    //             width: 200px;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }

  &.view-more-popup {
    // width: 100%;
    // padding: 0;
    // overflow-y: auto;
    padding: 0 10px;
    .result-table {
      height: 450px;
      overflow-y: auto;
    }
    .result-table-row {
      > div,
      > a {
        // flex-wrap: nowrap;
        > div {
          // flex-grow: 1 !important;
          // flex-basis: 0 !important;
          // width: auto !important;
          padding: 15px 10px !important;
          span {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-all;
          }
          &::after {
            display: none !important;
          }
        }
      }
    }
    .result-table-row.titles > div > div {
      padding: 15px 10px;
      text-transform: none;
    }
  }
}
@include media-breakpoint-down(sm) {
  .search-result-table {
    width: 100%;
    padding: 0;
    overflow-y: auto;
    .result-table {
      width: 1000px;
      .result-table-row {
        > a,
        > div {
          > div {
            flex: 0 0 200px;
            width: 200px;
            &:nth-of-type(1) {
              flex: 0 0 200px;
              width: 200px;
            }
          }
        }
        &.titles {
          > div > div {
            flex: 0 0 200px;
            width: 200px;
          }
        }
      }
    }
  }
}

.search-result-table-container {
  min-height: 100vh;
}

.chemical-name-limit{
  display: inline-block;
  width: 80px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.no-result-found {
  margin-top: 55px;
  margin-bottom: 60px;
  text-align: center;
  h3 {
    margin-bottom: 15px;
    line-height: 28px;
    font-size: 24px;
    font-weight: $font-weight-normal;
    color: $brownish-grey;
  }
  p {
    line-height: 21px;
    font-size: 14px;
    line-height: 1.5;
    color: $warm-grey;
  }
}
