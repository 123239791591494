.post-block {
  $this: &;
  > a {
    display: block;
    border: solid 1px $light-grey;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    transition: all 0.1s ease-in-out;
    &:hover {
      text-decoration: none;
      box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.17);
      span {
        &:after {
          right: -27px;
        }
      }
    }
  }
  &__featured-image {
    height: 180px;
    width: 100%;
    background-color: #f1f1f1;
    background-image: url(#{$placeholderBg});
    background-repeat: no-repeat;
    background-position: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__description {
    padding: 26px 35px 21px 35px;
    h4 {
      margin-bottom: 10px;
      line-height: 15px;
      font-size: 13px;
      font-weight: $font-weight-medium;
      color: rgba(77, 121, 150, 0.5);
      text-transform: uppercase;
    }
    h3 {
      margin-bottom: 12px;
      color: $brownish-grey;
      letter-spacing: -0.4px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    h2 {
      margin-bottom: 12px;
      line-height: 28px;
      color: $brownish-grey;
      letter-spacing: 0.2px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    p {
      margin-bottom: 18px;
      line-height: 21px;
      font-size: 14px;
      color: $brown-grey;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    > span {
      @include primary-link;
    }
  }
  &.blog-small {
    margin-bottom: 20px;
    // #{$this}__featured-image {
    //   height: 190px;
    // }
    #{$this}__description {
      // height: calc(100% - 190px);
      // padding: 30px 30px 45px;
      h4 {
        color: $black-grey;
      }
      h3 {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 19px;
        font-family: $secondary-font;
      }
      p {
        margin-bottom: 30px;
        line-height: 14px;
        font-size: 12px;
        color: $warm-grey;
      }
    }
  }
  &.resource-small {
    #{$this}__featured-image {
      height: 190px;
    }
    #{$this}__description {
      padding: 30px;
      h4 {
        @include clearfix;
        span {
          font-size: 12px;
          font-weight: $font-weight-medium;
          letter-spacing: 1px;
          color: rgba(77, 121, 150, 0.5);
          line-height: 13px;
          &:not(:last-of-type) {
            &:after {
              content: ',';
              display: inline-block;
              padding-right: 5px;
            }
          }
        }
      }
      h3 {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 19px;
        font-family: $secondary-font;
        color: $brownish-grey;
      }
      p {
        margin-bottom: 30px;
        line-height: 14px;
        font-size: 12px;
        color: $warm-grey;
      }
    }
  }
  &.resource-related-small {
    margin-bottom: 20px;
  }
  &.sidebar-articles {
    height: auto;
    margin-bottom: 25px;
    #{$this}__featured-image {
      height: 180px;
    }
    #{$this}__description {
      text-align: center;
      padding: 20px 30px;
      h3 {
        font-size: 16px;
        text-transform: uppercase;
      }
      p {
        font-size: 12px;
        line-height: normal;
      }
      span {
        position: relative;
        left: initial;
        bottom: initial;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .post-block {
    $this: &;
    height: 100%;
    &.sidebar-articles {
      #{$this}__featured-image {
        height: 120px;
      }
    }
    > a {
      height: 100%;
    }
    &__description {
      height: calc(100% - 180px);
      position: relative;
      padding-bottom: 44px;
      > span {
        position: absolute;
        left: 35px;
        bottom: 24px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .post-block {
    $this: &;
    &.blog-large {
      margin-bottom: 0;
      a {
        height: 257px;
        display: flex;
        flex-direction: row;
        &:hover {
          span {
            &:after {
              right: -31px;
            }
          }
        }
      }
      #{$this}__featured-image {
        flex: 0 0 calc(100% - 570px);
        height: 100%;
      }
      #{$this}__description {
        flex: 0 0 570px;
        height: auto;
        padding: 50px 46px 54px 50px;
        h2 {
          margin-bottom: 11px;
        }
        p {
          margin-bottom: 12px;
          font-size: 12px;
          color: $warm-grey;
          line-height: 14px;
          letter-spacing: 0.05px;
        }
        span {
          left: 50px;
          bottom: 30px;
          font-size: 12px;
          &:after {
            right: -21px;
          }
        }
      }
    }
    &.blog-small {
      margin-bottom: 0;
      #{$this}__featured-image {
        height: 190px;
      }
      #{$this}__description {
        height: calc(100% - 190px);
        padding: 30px 30px 45px;
        span {
          left: 30px;
          bottom: 30px;
        }
      }
    }
    &.blog-related {
      margin: 0 !important;
    }
    &.resource-small {
      #{$this}__description {
        padding: 30px 30px 59px;
        h4 {
          span {
            text-transform: none;
          }
        }
        span {
          left: 29px;
          bottom: 39px;
        }
      }
    }
  }
}
