@include media-breakpoint-down(lg) {
    .advance-product-result-table {
        width: 100%;
        padding: 0;
        overflow-y: auto;
        margin-bottom: 20px;
        .result-count {
            margin-bottom: 30px;
            h3 {
                display: flex;
                align-items: center;
                font-size: 24px;
                color: $brownish-grey;
                span {
                    font-size: 13px;
                    color: $warm-grey;
                    margin-left: 10px;
                }
            }
        }
        .result-table {
            width: 1000px;
            .result-table-row {
                a {
                    div {
                        flex: 0 0 161px;
                        &:nth-of-type(1) {
                            flex: 0 0 16.05%;
                        }
                    }
                }
                &.titles {
                    >div {
                        >div {
                            flex: 0 0 16.05%;
                            &:last-child {
                                flex: 0 0 20.05%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .advance-product-result-table {
        // overflow-x: hidden;
        // width: auto;
        // padding: 0;
        // overflow-y: hidden;
        .row {
            .col-lg-12 {
                padding: 0;
            }
        }
        .result-count {
            margin-bottom: 32px;
            h3 {
                font-size: 28px;
                span {
                    margin-left: 12px;
                    margin-top: 2px;
                    font-size: 13px;
                    color: $warm-grey !important;
                }
            }
        }
        .result-table {
            // width: auto;
            .result-table-row {
                a {
                    div {
                        flex: 0 0 195px;
                        &:nth-of-type(1) {
                            flex: 0 0 19.4%;
                        }
                        &:nth-of-type(3) {
                            flex: 0 0 17.4%;
                        }
                        &:nth-of-type(4) {
                            flex: 0 0 16.4%;
                        }
                        &:nth-of-type(5) {
                            flex: 0 0 13.69%;
                        }
                    }
                }
                &.titles {
                    >div {
                        >div {
                            flex: 0 0 16.69%;
                            border-left: none !important;
                            border-right: none !important;
                            &:nth-of-type(1) {
                                flex: 0 0 19.4%;
                            }
                            &:nth-of-type(3) {
                                flex: 0 0 17.4%;
                            }
                            &:nth-of-type(5) {
                                flex: 0 0 13.69%;
                            }
                            &:last-child {
                                flex: 0 0 16.4%;
                            }
                        }
                    }
                }
            }
        }
    }
}