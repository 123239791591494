.source-methology-text-block {
  .container {
    padding-top: 30px;
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .source-methology-text-block {
    padding-bottom: 52px;
    .container {
      padding-top: 43px;
      .col-sm-10{
        padding: 0 20px;
      }
    }
  }
}
