@include media-breakpoint-down(lg) {
  .header {
    background-color: $white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
    // temporary fix to keep footer bottom on blank pages
    + div {
      min-height: 100vh;
    }
  }

  .header-top-bar {
    padding: 3px 0;
    background: $grass;
    p {
      display: inline-block;
      margin: 0 5px 0 0;
      line-height: 11px;
      font-size: 8px;
      letter-spacing: 0.5px;
      color: $white;
    }
    img {
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      object-fit: contain;
    }
  }

  .header-logo {
    padding: 10px 0;
    img {
      height: 30px;
    }
  }

  .header-menu-toggle {
    @include icons;
    float: right;
    width: 32px;
    height: 32px;
    margin-top: 9px;
    overflow: hidden;
    border: none;
    box-shadow: none;
    color: transparent;
    background-position: -39px -19px;
    background-color: transparent;
  }

  .header-links {
    display: none;
    position: fixed;
    top: 70px;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px 40px;
    margin: 0 auto;
    background: $white;
    overflow: auto;
    &.show-menu {
      display: block;
    }
    li {
      list-style: none;
      a {
        display: block;
        padding: 10px 0;
        line-height: 15px;
        font-size: 13px;
        color: $brown-grey;
        &:hover {
          color: $cadet-blue;
          text-decoration: none;
        }
        &.link-disable{
          pointer-events: none !important;
        }
        &.link-parent-active{
          color: $cadet-blue;
        }
      }
    }
    > li {
      position: relative;
      > a {
        text-transform: uppercase;
      }
      &:hover {
        > a {
          color: $cadet-blue;
          text-decoration: none;
        }
      }
      > ul {
        padding: 5px 0;
        margin: 0;
        background-color: $white;
        border-top: 1px solid $cadet-blue;
        > li {
          > a {
            display: block;
            padding: 5px 25px;
            transition: all 0.3s ease-in-out;
            &:hover {
              background-color: $cadet-blue;
              color: $white;
            }
            &.link-sub-active{
              background-color: $cadet-blue;
              color: $white;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .header-links {
    padding: 20px 60px;
  }
}

@include media-breakpoint-down(xs) {
  .header-links {
    padding: 20px;
  }
}
