.health {
  table {
    tr {
      td {
        .healthsign {
          position: relative;
          &::after {
            content: '';
            position: absolute;
            //background-image: url('../../../../images/icons/icons.svg');
            @include icons;
            background-position: -93px -35px;
            width: 16px;
            height: 16px;
            top: 1px;
            left: 10px;
          }
        }
        .symptoms-table {
          margin-top: 10px;
          border: solid 1px $very-light-blue;
          thead {
            tr {
              background-color: $dark-navy-blue;
              th {
                padding: 11.5px 20px 9px 18px;

                border: none;
                color: $white;
                font-weight: $font-weight-medium;
                text-transform: uppercase;
                &:nth-of-type(1) {
                  width: 200px;
                }
                &:nth-of-type(2) {
                  width: 200px;
                }
              }
            }
          }
          tbody {
            tr {
              background-color: $white;
              border-bottom: solid 1px $very-light-blue;
              td {
                padding: 11.5px 20px 9px 18px !important;

                &:nth-of-type(1) {
                  width: 200px;
                }
                &:nth-of-type(2) {
                  width: 200px;
                }
              }
            }
          }
        }
      }
    }
  }
}
