.viewmore-modal {
  max-width: 870px;
  height: 620px;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(77, 121, 150, 0.13);
  border: solid 1px $very-light-blue;
  background-color: $white;
  .modal-content {
    border: none;
  }
  .view-more-popup {
    &.related-chemicals {
      .result-table {
        .result-table-row {
          background-color: $white !important;
          border-bottom: 1px solid $very-light-blue;
          &:last-child {
            border-bottom: none;
          }
          a {
            pointer-events: none;
            > div {
              border-right: none;
              border-left: none;
              span {
                display: block;
              }
              &:nth-of-type(1) {
                color: $brownish-grey;
              }
            }
          }
        }
      }
    }
  }

  .close {
    position: absolute;
    cursor: pointer;
    right: 23px;
    top: 20px;
    z-index: 99;
    &::after {
      content: '';
      display: block;
      background-image: url('../../../../images/icons/close-icon.svg');
      background-repeat: no-repeat;
      width: 12px;
      height: 12px;
    }
  }

  .title-container {
    display: flex;
    margin-bottom: 32px;
    margin-top: 21px;
    h2 {
      margin-right: 15px;
      font-size: 28px;
      color: $brownish-grey;
      text-transform: capitalize;
    }

    .logo-wrapper {
      display: flex;
      .tooltip-holder {
        margin-right: 16px;
        span {
          position: relative;
          display: block;
          &#badActor {
            width: 18px;
            height: 18px;
            &::after {
              content: '';
              position: absolute;
              width: 18px;
              height: 18px;
              background-image: url('../../../../images/icons/badactor.svg');
              top: 3px;
            }
          }
          &#highHazardous {
            width: 21px;
            height: 18px;
            &::after {
              content: '';
              position: absolute;
              width: 21px;
              height: 18px;
              background-image: url('../../../../images/icons/highhazaradous.svg');
              top: 3px;
            }
          }
        }
      }
    }
  }

  .otherlist {
    width: 100%;
    max-height: 469px;
    overflow-x: hidden;
    overflow-y: scroll;
    border: solid 1px $very-light-blue;
    background-color: $pale-grey;
    border-radius: 4px;
    .title {
      padding: 10px 20px;
      color: $brownish-grey;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: $font-weight-medium;
      border-bottom: solid 1px $very-light-blue;
      display: flex;
      align-items: center;
      span {
        font-size: 10px;
        color: $warm-grey;
        margin-left: 5px;
        font-weight: $font-weight-normal;
        text-transform: none;
        width: 100px;
      }
    }
    .chemical-list-wrapper {
      display: flex;
      flex-wrap: wrap;
      .item {
        &:nth-of-type(2n + 1) {
          border-right: solid 1px $very-light-blue;
        }
        &:nth-last-of-type(1) {
          margin-bottom: -1px;
        }
        &:nth-last-of-type(2) {
          margin-bottom: -1px;
        }

        flex: 0 0 50%;
        position: relative;
        padding: 10px 20px;
        font-size: 13px;
        line-height: 15px;
        color: #616060;
        border-bottom: solid 1px $very-light-blue;
        width: 50%;
        a {
          color: $brownish-grey;
          &:hover {
            text-decoration: none;
            color: $cadet-blue;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .viewmore-modal {
    margin: 6rem 21.3rem;
  }
}
@include media-breakpoint-down(sm) {
  .viewmore-modal {
    .search-result-table {
      &.view-more-popup {
        &.advanceDetailPage {
          .result-table {
            width: 660px;
          }
        }
        .result-table {
          width: 900px;
          .result-table-row {
            a {
              > div {
                &.popup-col-6,
                &.popup-col-8 {
                  flex: 0 0 110px;
                }
              }
            }
            &.titles {
              > div {
                > div {
                  &.popup-col-6,
                  &.popup-col-8 {
                    flex: 0 0 110px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
