.featured-heading {
  text-align: center;
  margin-bottom: 45px;
  margin-top: 14px;
  position: relative;
  * {
    letter-spacing: 10px;
    font-size: 16px;
    color: $brownish-grey;
    font-weight: $font-weight-normal;
    text-transform: uppercase;
    background-color: $white;
    display: inline-block;
    padding: 0 13px 0 16px;
    
  }
}

@include media-breakpoint-up(sm) {
  .featured-heading {
    text-align: center;
    margin-bottom: 45px;
    margin-top: 14px;
    position: relative;
    * {
      letter-spacing: 10px;
      font-size: 16px;
      color: $brownish-grey;
      font-weight: $font-weight-normal;
      text-transform: uppercase;
      background-color: $white;
      display: inline-block;
      padding: 0 13px 0 16px;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: $very-light-grey;
        left: 0;
        right: 0;
        top: 10px;
        z-index: -1;
      }
    }
  }
}
