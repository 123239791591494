.home-related-articles {
  padding: 0 0 50px 0;
  .row:nth-of-type(2) {
    > div {
      margin-bottom: 20px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .home-related-articles {
    .post-block {
      margin: 0 14px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .home-related-articles {
    .post-block {
      &__description {
        padding: 20px;
        > span {
          left: 20px;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .home-related-articles {
    .post-block {
      &__description {
        > span {
          left: 0;
        }
      }
    }
  }
}
