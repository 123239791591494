.chemical-left-filters {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-right: -1px;
  background: $pale-grey;
  border: 1px solid $very-light-blue;
  .target-pest-filter,
  .chemical-type-filter {
    flex: 0 0 45%;
    .title {
      height: 38px;
      padding: 10px 20px;
      border: 1px solid $very-light-blue;
      border-bottom: none;
      border-radius: 4px 4px 0 0;
      background-color: $dark-navy-blue;
      line-height: 18px;
      font-size: 15px;
      font-weight: $font-weight-medium;
      color: $white;
      text-transform: uppercase;
    }
  }
  .target-pest {
    margin-right: 10px;
  }
  > p {
    flex: 0 0 10%;
    margin: 0;
    text-align: center;
    line-height: 38px;
    font-size: 10px;
    font-weight: $font-weight-bold;
    color: $cadet-blue;
    text-transform: uppercase;
  }
  ul.filter {
    height: 227px;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0 0 4px 4px;
    border-left: 1px solid $very-light-blue;
    border-right: 1px solid $very-light-blue;
    border-bottom: 1px solid $very-light-blue;
    li {
      list-style: none;
      line-height: 15px;
      font-size: 13px;
      color: $brownish-grey;
      background: $white;
      border-bottom: solid 1px $very-light-blue;
      cursor: pointer;
    }
    > li {
      position: relative;
      padding: 15px 35px 15px 20px;
      transition: all 0.15s ease-in-out;
      &:after {
        @include icons;
        content: '';
        position: absolute;
        right: 18px;
        top: 22px;
        display: inline-block;
        width: 8px;
        height: 4px;
        background-position: -165px -26px;
      }
      > ul {
        margin: 15px -35px -1px -20px;
        background-color: $very-light-blue;
        border-top: 1px solid rgba(77, 121, 150, 0.13);
        > li {
          padding: 10px 20px;
          line-height: 15px;
          font-size: 13px;
          color: $brownish-grey;
          background-color: $very-light-blue;
          border-bottom: 1px solid rgba(77, 121, 150, 0.13);
          transition: background-color 0.15s ease-in-out;
          &.selected {
            position: relative;
            color: $cadet-blue;
            &:after {
              @include icons;
              content: '';
              position: absolute;
              right: 18px;
              top: 14px;
              display: inline-block;
              width: 10px;
              height: 7px;
              background-position: -165px -38px;
            }
          }
          &:hover {
            margin-left: -1px;
            // margin-right: -1px;
            background-color: $cadet-blue;
            color: $white;
            &.selected {
              position: relative;
              &:after {
                background-position: -165px -47px;
              }
            }
          }
        }
      }
      &.collapsed {
        > ul {
          display: none;
        }
        &:after {
          background-position: -165px -32px;
        }
        &.selected {
          background: $cadet-blue;
          color: $white;
          &:after {
            width: 10px;
            height: 7px;
            background-position: -150px -44px;
          }
        }
      }
      &:not(.collapsed) {
        // margin-left: -1px;
        // margin-right: -1px;
        padding-bottom: 0;
        background: $cadet-blue;
        color: $white;
        &.selected {
          // background-color: $very-light-blue;
          // color: $brownish-grey;
          &:after {
            // width: 10px;
            // height: 7px;
            // background-position: -150px -44px;
          }
        }
      }
      &:hover.collapsed:not(.selected) {
        background-color: $cadet-blue;
        color: $white;
        // border-left-color: $cadet-blue;
        // border-right-color: $cadet-blue;
        &:after {
          background-position: -150px -44px;
        }
      }
    }
    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px $very-light-blue;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b7c4d2;
      outline: 1px solid $very-light-blue;
    }
  }
}

@include media-breakpoint-up(xl) {
  .chemical-left-filters-wrapper {
    padding-right: 0;
  }
  .chemical-left-filters {
    .target-pest-filter,
    .chemical-type-filter {
      flex: 0 0 262px;
    }
    > p {
      flex: 0 0 20px;
    }
  }
}

@include media-breakpoint-down(md) {
  .chemical-left-filters-wrapper {
    padding-right: 15px;
  }
  .chemical-left-filters {
    flex-wrap: wrap;
    margin-right: 0;
    margin-top: 0;
    .target-pest-filter,
    .chemical-type-filter {
      flex: 0 0 100%;
    }
    > p {
      flex: 0 0 100%;
    }
  }
}

// @include media-breakpoint-down(sm) {
//   .chemical-left-filters {
//     margin-top: 50px;
//   }
// }
