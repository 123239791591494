.ban-map-chemical-detials {
  margin-top: 30px;
  padding: 20px 30px 10px 30px;
  height: 145px;
  border-radius: 4px;
  box-shadow: 0 0 15px 5px rgba(47, 47, 47, 0.15);
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  > div {
    &:nth-of-type(1) {
      @include clearfix;
      margin-bottom: 20px;
      > span {
        float: left;
        &:nth-of-type(1) {
          min-width: 80px;
          margin-right: 22px;
          line-height: 40px;
          font-size: 15px;
          font-weight: $font-weight-medium;
          color: $brownish-grey;
          text-transform: capitalize;
        }
        &:nth-of-type(2) {
          position: relative;
          display: inline-block;
          width: 195px;
          padding: 12px 0;
          border-top: 1px solid $very-light-blue;
          border-bottom: 1px solid $very-light-blue;
          line-height: 15px;
          font-size: 13px;
          color: $warm-grey;
          > span {
            position: absolute;
            top: 10px;
            right: 0;
            line-height: 19px;
            font-family: $secondary-font;
            font-size: 16px;
            text-align: right;
            color: $brownish-grey;
          }
        }
      }
    }
    &:nth-of-type(2) {
      @include clearfix;
      margin-top: 20px;
      > span {
        float: left;
        min-width: 102px;
        line-height: 18px;
        font-size: 15px;
        font-weight: $font-weight-medium;
        color: $brownish-grey;
      }
      > ul {
        @include clearfix;
        float: right;
        width: calc(100% - 102px);
        margin: 0 0 5px;
        li {
          float: left;
          margin-top: 2px;
          margin-right: 20px;
          margin-bottom: 5px;
          list-style: none;
          line-height: 15px;
          font-size: 13px;
          color: $warm-grey;
          text-transform: capitalize;
        }
      }
    }
  }
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    outline: 1px solid slategrey;
  }
}

@include media-breakpoint-down(sm) {
  .ban-map-chemical-detials {
    margin-top: 25px;
    margin-bottom: 35px;
    padding: 20px;
    height: auto;
    max-height: 500px;
    > div {
      &:nth-of-type(1) {
        > span {
          float: none;
          display: block;
          &:nth-of-type(1) {
            margin-right: 0;
            margin-bottom: 10px;
          }
          &:nth-of-type(2) {
            margin-bottom: 10px;
          }
        }
      }
      &:nth-of-type(2) {
        @include clearfix;
        margin-top: 20px;
        > span {
          float: none;
          display: block;
          margin-bottom: 10px;
        }
        > ul {
          float: none;
          width: 100%;
        }
      }
    }
  }
}
