.not-found-container {
  padding-top: 100px;
  .not-found-content {
    text-align: center;
    h1,
    h2,
    p {
      color: $brownish-grey;
    }

    h1 {
      font-size: 50px;
      margin-bottom: 20px;
      font-weight: $font-weight-medium;
    }
    h2 {
      margin-bottom: 10px;
    }
    p {
      font-weight: $font-weight-light;
    }
    a {
      color: $cadet-blue;
      font-weight: $font-weight-medium;
      &:hover {
        text-decoration: none;
        opacity: 0.8;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .not-found-container {
    .not-found-content {
      h1 {
        font-size: 60px;
      }
    }
  }
}
