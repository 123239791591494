.filter-selections {
    margin: 20px 0 30px 0;
    border-top: 1px solid $very-light-pink;
    border-bottom: 1px solid $very-light-pink;
    padding: 20px 0;
    .selection-title-wrapper {
        text-align: center;
        margin-top: -35px;
        h6 {
            display: inline-block;
            font-size: 12px;
            color: $brownish-grey;
            margin-bottom: 0;
            padding: 0 10px;
            background-color: $white;
        }
    }
    ul {
        &.selected-items {
            padding-top: 10px;
            li {
                list-style: none;
                display: inline-block;
                margin-right: 5px;
                margin-bottom: 5px;
                border-radius: 5px;
                padding: 7px 10px;
                font-size: 13px;
                color: $black-grey;
                font-weight: $font-weight-medium;
                background-color: $very-light-pink;
                line-height: 1;
                cursor: pointer;
                &:hover {
                    background-color: $cadet-blue;
                    color: $white;
                    transition: all 0.2s ease-in-out;
                    i {
                        width: 9px;
                        height: 9px;
                        position: relative;
                        display: inline-block;
                        margin-left: 6px;
                        cursor: pointer;
                        &.close {
                            &::after {
                                content: '';
                                @include icons;
                                background-position: -38px -60px;
                                width: 9px;
                                height: 9px;
                                position: absolute;
                            }
                        }
                    }
                }
                i {
                    width: 9px;
                    height: 9px;
                    position: relative;
                    display: inline-block;
                    margin-left: 6px;
                    cursor: pointer;
                    &.close {
                        &::after {
                            content: '';
                            @include icons;
                            background-position: -27px -60px;
                            width: 9px;
                            height: 9px;
                            position: absolute;
                        }
                    }
                }
            }
        }
    }
    .button-holder {
        text-align: center;
        a {
            @include custom-form-button(11px 30px 11px 16px, $cadet-blue);
            letter-spacing: 0;
            span {
                &::after {
                    top: 5px;
                }
            }
            &.disable {
                opacity: 0.6;
                pointer-events: none;
            }
            &.enable {
                opacity: 1;
                pointer-events: visible;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .filter-selections {
        margin: 20px 0 42px 0;
        ul {
            &.selected-items {
                li {
                    margin-right: 12px;
                    margin-bottom: 10px;
                }
            }
        }
        .button-holder {
            margin-top: 19px;
        }
    }
}