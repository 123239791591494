.data-table-two-columns {
  margin-bottom: 20px;
  border-radius: 4px;
  border: solid 1px $very-light-blue;
  background-color: $pale-grey;
  .data-table-heading {
    padding: 10px 20px;
    border-bottom: solid 1px $very-light-blue;
    font-size: 15px;
    line-height: 18px;
    font-weight: $font-weight-medium;
    text-transform: uppercase;
  }
  .data-table-content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -1px;
    > div {
      flex: 0 0 50%;
      position: relative;
      padding: 10px 20px;
      font-size: 13px;
      line-height: 15px;
      color: $brownish-grey;
      border-bottom: solid 1px $very-light-blue;
      &:nth-of-type(2n + 1) {
        border-right: solid 1px $very-light-blue;
      }
      &:nth-of-type(2n) {
        margin-left: -1px;
        border-left: solid 1px $very-light-blue;
      }
    }
  }
  .button-holder {
    text-align: center;
    border-top: solid 1px $very-light-blue;
    .viewmore {
      color: $cadet-blue;
      text-transform: uppercase;
      font-weight: $font-weight-medium;
      text-decoration: none;
      position: relative;
      background: transparent;
      font-size: 13px;
      border: none;
      padding: 10px 0;
      &:hover {
        opacity: 0.6;
      }

      &::before {
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        @include icons;
        background-position: -93px -16px;
        left: -20px;
        top: 12px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .data-table-two-columns {
    .data-table-heading {
      text-align: center;
    }
    .data-table-content {
      > div {
      }
    }
  }
}
