.inner-upper-container {
  .title-container {
    display: flex;
    flex: 0 0 50%;
    align-items: center;
    margin-bottom: 32px;
    margin-top: 21px;
    h2 {
      margin-right: 15px;
      font-size: 28px;
      color: $brownish-grey;
      text-transform: capitalize;
      word-break: break-all;
    }

    .logo-wrapper {
      @include clearfix;
      margin-left: 10px;
      display: inline-block;
      .tooltip-holder {
        float: left;
        margin-right: 16px;
        span {
          position: relative;
          display: block;
          &#badActor {
            width: 18px;
            height: 18px;
            &::after {
              content: '';
              position: absolute;
              width: 18px;
              height: 18px;
              background-image: url('../../../../images/icons/badactor.svg');
              top: 3px;
            }
          }
          &#highHazardous {
            width: 21px;
            height: 18px;
            &::after {
              content: '';
              position: absolute;
              width: 21px;
              height: 18px;
              background-image: url('../../../../images/icons/highhazaradous.svg');
              top: 3px;
            }
          }
        }
      }
    }
  }
  .button-container {
    display: block;
    flex: 0 0 50%;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
    a {
      width: 200px;
      margin-bottom: 10px;
      &.blue-btn {
        @include primary-button-small(12px 30px 12px 15px, $cadet-blue);
      }
      &.red-btn {
        @include primary-button-small(12px 30px 12px 14px, $salmon);
        margin-left: 5px;
        span {
          &::after {
            background-position: 0 -11px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .inner-upper-container {
    display: flex;
    .button-container {
      margin-left: auto;
      margin-top: 14px;
      display: flex;
      a {
        width: auto;
        &.red-btn {
          margin-left: 12px;
        }
      }
    }
  }
}
