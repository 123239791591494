.blog-inner-form {
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 40px 20px;
  background: url($blogFormBg) no-repeat;
  background-size: cover;
  h2 {
    margin-bottom: 20px;
    text-align: center;
    color: $white;
  }
  form {
    margin-top: 20px;
    input {
      &[type='text'],
      &[type='email'] {
        height: 50px;
        width: 100%;
        margin-bottom: 15px;
        padding-left: 19px;
        padding-right: 15px;
        margin-right: 30px;
        border-radius: 4px;
        border: solid 1px $very-light-blue;
        background-color: $white-grey;
        &::-webkit-input-placeholder {
          color: $brown-grey;
          font-size: 14px;
        }
        &::-moz-placeholder {
          color: $brown-grey;
          font-size: 14px;
        }
        &:-ms-input-placeholder {
          color: $brown-grey;
          font-size: 14px;
        }
        &:-moz-placeholder {
          color: $brown-grey;
          font-size: 14px;
        }
      }
      &[type='submit'] {
        @include form-button;
        width: 100%;
        // TODO remove the backgound image and image file when forms are embedded
        background-image: url('../../../images/temp/white-arrow.svg');
        background-repeat: no-repeat;
        background-position: center right 30px;
      }
    }
  }
  #can_embed_form_inner {
    h2{      
      color: #fff;
      text-align: center;
      padding-bottom: 30px;
      text-transform: uppercase;
      letter-spacing: 2px;
      position: relative;
      font-weight: 400;
      font-size: 18px;
      border-bottom: 1px solid #e0e0e0;
    }
    h4{
      color: #fff;
      display: none;
    }
    input {
      &[type='submit'] {
        @include form-button;
        width: 100%;
        // TODO remove the backgound image and image file when forms are embedded
        background-image: url('../../../images/temp/white-arrow.svg');
        background-repeat: no-repeat;
        background-position: center right 30px;
      }
    }
  }

}

@include media-breakpoint-up(lg) {
  .blog-inner-form {
    margin-top: 30px;
    margin-bottom: 40px;
    padding: 40px 30px;
    h2 {
      margin-bottom: 40px;
    }
    form {
      margin-top: 40px;
      input {
        &[type='text'],
        &[type='email'] {
          height: 50px;
          width: calc((100% / 5 * 2) - 30px);
          margin-bottom: 0;
        }
        &[type='submit'] {
          width: calc(100% / 5);
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .blog-inner-form {
    margin-top: 42px;
    margin-bottom: 70px;
    padding: 52px 100px 50px;
    form {
      input {
        &[type='text'],
        &[type='email'] {
          width: 370px;
        }
        &[type='submit'] {
          width: 170px;
        }
      }
    }
  }
}
