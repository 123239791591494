.ca-search-result-table {
  margin-top: 13px;
  .table-wrapper {
    @include clearfix;
    margin-bottom: 30px;
    > div {
      float: left;
      &.description {
        .title {
          padding-left: 20px;
          line-height: 46px;
          font-size: 13px;
          font-weight: $font-weight-medium;
          color: $white;
          background-color: $dark-navy-blue;
        }
        > div:not(.title) {
          padding: 15px 0 10px;
          height: 205px;
          > div {
            &:first-of-type {
              margin-bottom: 15px;
              text-transform: uppercase;
            }
            margin-bottom: 10px;
            margin-left: 20px;
            line-height: 15px;
            font-size: 13px;
            color: $brownish-grey;
            a {
              color: $deep-sky-blue;
              text-decoration: none;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              &:hover {
                color: $cadet-blue;
              }
            }
          }
          &:nth-of-type(2n + 1) {
            background-color: $pale-grey;
          }
        }
      }
      &.years {
        overflow-y: auto;
        > div {
          &:first-of-type {
            line-height: 46px;
            font-size: 13px;
            font-weight: $font-weight-medium;
            color: $white;
            background-color: $dark-navy-blue;
            > div > div {
              position: relative;
              &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                background-color: $dark-navy-blue;
                z-index: -1;
              }
            }
          }
          > div {
            display: flex;
            > div {
              padding-left: 20px;
              flex: 0 0 100px;
            }
          }
          &:not(:first-of-type) {
            padding-top: 95px;
            height: 205px;
            > div {
              > div {
                height: 25px;
                border-left: 1px solid $very-light-blue;
                padding-left: 20px;
                line-height: 15px;
                padding-bottom: 10px;
                font-size: 13px;
                color: $brownish-grey;
              }
              &:first-of-type {
                > div {
                  position: relative;
                  &:after {
                    content: '';
                    display: inline-block;
                    width: 1px;
                    height: 95px;
                    position: absolute;
                    left: -1px;
                    top: -95px;
                    background-color: $very-light-blue;
                  }
                }
              }
              &:last-of-type {
                > div {
                  height: 35px;
                  padding-bottom: 20px;
                }
              }
            }
            &:nth-of-type(2n + 1) {
              background-color: $pale-grey;
            }
          }
        }
      }
      &.more-years {
        > div {
          &:first-of-type {
            position: relative;
            padding-left: 20px;
            line-height: 46px;
            font-size: 13px;
            font-weight: $font-weight-medium;
            color: $white;
            background-color: $dark-navy-blue;
            text-transform: uppercase;
            cursor: pointer;
            &::after {
              @include icons;
              position: absolute;
              left: 0;
              top: 14px;
              content: '';
              display: inline-block;
              width: 14px;
              height: 14px;
              background-position: -130px -42px;
            }
          }
          > div {
            display: none;
            width: 180px;
            padding: 15px 20px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
            select {
              height: 35px;
              width: 100%;
              margin-bottom: 10px;
              padding: 9px 14px 10px;
              line-height: 15px;
              font-size: 13px;
              color: $warm-grey;
              border-radius: 4px;
              border: solid 1px $very-light-blue;
              -webkit-appearance: none;
              -moz-appearance: none;
              text-indent: 1px;
              text-overflow: '';
              background: #f8fbff url(#{$select-fill-icon}) no-repeat center
                right 14px;
              option {
                display: block;
                height: 25px;
                padding: 10px 15px;
                font-size: 13px;
                line-height: 1.77;
                color: $warm-grey;
                background-color: $white;
              }
              ::-ms-expand {
                display: none;
              }
            }
            .error {
              padding: 5px;
              color: $error;
              font-size: 12px;
              font-weight: $font-weight-medium;
              line-height: 14px;
              text-align: center;
            }
            button {
              margin-top: 5px;
              height: 38px;
              width: 100%;
              border-radius: 20.5px;
              background-color: $cadet-blue;
              color: $white;
              line-height: 15px;
              font-size: 13px;
              font-weight: $font-weight-medium;
              color: #ffffff;
              border: none;
              box-shadow: none;
              outline: 0;
              &.disable {
                pointer-events: none;
                opacity: 0.6;
              }
            }
            &.show {
              display: block;
            }
          }
        }
      }
    }
      .additional-scroll {
        overflow: auto;
        margin: -20px 0 0 0;
        .empty-scrollable:first-of-type {
          height: 1px;
          background-color: #ffffff;
        }
      }
  }
}

@include media-breakpoint-up(xl) {
  .ca-search-result-table {
    .table-wrapper {
      .description {
        width: 275px;
      }
      .years {
        width: 700px;
        > div {
          > div {
            > div {
            }
          }
        }
      }
      > div {
        &.more-years {
          width: 195px;
          > div {
            &:first-of-type {
              padding-left: 65px;
              &:after {
                left: 46px;
                top: 16px;
              }
            }
            &:nth-of-type(2) {
              margin-left: 15px;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(xl) {
}

@include media-breakpoint-down(lg) {
  .ca-search-result-table {
    .table-wrapper {
      .description {
        width: 275px;
      }
      .years {
        width: 500px;
        > div {
          > div {
            > div {
            }
          }
        }
      }
      .more-years {
        width: 150px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .ca-search-result-table {
    .table-wrapper {
      .description {
        width: 275px;
      }
      .years {
        width: 300px;
        > div {
          > div {
            > div {
            }
          }
        }
      }
      .more-years {
        width: 150px;
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  .ca-search-result-table {
    .table-wrapper {
      .description {
        width: 150px;
      }
      .years {
        width: 160px;
      }
      .more-years {
        width: 20px;
        > div {
          position: relative;
          width: 20px;
          height: 46px;
          color: transparent;
          > div.show {
            position: absolute;
            right: 0;
            background: $white;
          }
        }
      }
    }
  }
}

@include media-breakpoint-between(sm, md) {
  .ca-search-result-table {
    .table-wrapper {
      // .description {
      //   width: 150px;
      // }
      .years {
        width: 345px;
      }
      // .more-years {
      //   width: 20px;
      //   > div {
      //     position: relative;
      //     width: 20px;
      //     height: 45px;
      //     color: transparent;
      //     > div.show {
      //       position: absolute;
      //       right: 0;
      //       background: $white;
      //     }
      //   }
      // }

    }
  }
}
