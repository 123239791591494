@include media-breakpoint-only(xl) {
  .header {
    background-color: $white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
    .row {
      > div {
        &:nth-of-type(2) {
          padding-left: 0;
        }
      }
    }
    // temporary fix to keep footer bottom on blank pages
    + div {
      min-height: calc(100vh - 260px);
    }
  }

  .header-top-bar {
    padding: 6px 0;
    background: $grass;
    p {
      display: inline-block;
      margin: 0 10px 0 0;
      line-height: 12px;
      font-size: 10px;
      letter-spacing: 1px;
      color: $white;
    }
    img {
      display: inline-block;
      width: 22px;
      height: 22px;
      border: 1px solid $white;
      border-radius: 100%;
      object-fit: contain;
    }
  }

  .header-logo {
    padding: 10px 0;
    img {
      height: 40px;
    }
  }

  .header-links {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    li {
      list-style: none;

      a {
        line-height: 15px;
        font-size: 13px;
        color: $brown-grey;
        &.link-disable {
          pointer-events: none !important;
        }
        &.link-parent-active {
          color: $cadet-blue;
        }
        &:hover {
          color: $cadet-blue;
          text-decoration: none;
        }
      }
    }
    > li {
      position: relative;
      > a {
        line-height: 60px;
        text-transform: uppercase;
      }
      &:hover {
        > a {
          color: $cadet-blue;
          text-decoration: none;
        }
        > ul {
          display: block;
        }
      }
      > ul {
        z-index: 1;
        display: none;
        position: absolute;
        padding: 20px 0;
        border-radius: 0 0 20px 0;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
        background-color: $white;
        border-top: 1px solid $cadet-blue;
        > li {
          > a {
            display: block;
            padding: 10px 25px;
            transition: all 0.15s ease-in-out;
            &.link-sub-active {
              background-color: $cadet-blue;
              color: $white;
            }
            &:hover {
              background-color: $cadet-blue;
              color: $white;
            }
          }
        }
      }
      &:nth-of-type(3) {
        > ul {
          min-width: 158px;
        }
      }
    }
  }
}
