.prod_overview{
    table{
        &.manufacturer{
            tr{
                td{
                    
                    p{
                        line-height: 1.5;
                    }
                }
            }
        }
    }
}