.advance-featured-text {
  margin-top: 16px;
  margin-bottom: 21px;

  .text-block {
    padding-top: 0;
    h2 {
      margin-top: 0;
      font-size: 28px;
      color: $brownish-grey;
    }
    p {
      font-size: 14px;
      color: $warm-grey;
      line-height: 1.5;
    }
  }
}

@include media-breakpoint-up(lg) {
  .advance-featured-text {
    .text-block {
      h2 {
        margin-bottom: 24px;
      }
    }
  }
}
