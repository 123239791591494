.filter-container {
  margin-top: 30px;
  opacity: 0;
  -webkit-animation: fadein 1s forwards; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s forwards; /* Firefox < 16 */
  -ms-animation: fadein 1s forwards; /* Internet Explorer */
  -o-animation: fadein 1s forwards; /* Opera < 12.1 */
  animation: fadein 1s forwards;

  -webkit-animation-delay: 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation-delay: 1s; /* Firefox < 16 */
  -ms-animation-delay: 1s; /* Internet Explorer */
  -o-animation-delay: 1s; /* Opera < 12.1 */
  animation-delay: 1s;
 
  .filter-wrapper {
    padding: 18px 20px 23px 20px;
    box-shadow: 0 2px 10px 0 rgba(77, 121, 150, 0.13);
    border: solid 1px $very-light-blue;
    background-color: $white;
    form {
      .selections {
        display: block;
        align-items: center;
        span {
          font-size: 12px;
          color: $brownish-grey;
          text-transform: uppercase;
          font-weight: $font-weight-medium;
          margin-right: 19px;
        }
        > div {
          display: flex;
          align-items: center;
          margin-top: 10px;
          input {
            &[type='radio'] {
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              display: inline-block;
              position: relative;
              background-color: $medium-grey;
              color: #666;
              height: 20px;
              width: 20px;
              border: 0;
              border-radius: 10px;
              cursor: pointer;
              margin-right: 11px;
              outline: none;
              box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.11);
              // border: 1px solid transparent;
              border: solid 1px $very-light-blue;
            }
            &[type='radio']:checked::before {
              @include icons;
              position: absolute;
              width: 18px;
              height: 18px;
              left: 0;
              right: 0;
              top: -1px;
              bottom: 0;
              margin: 0 auto;
              background-position: -145px -24px;
              content: '';
              border-radius: 10px;
              border-color: $medium-grey;
            }
          }
          label {
            font-size: 12px;
            text-transform: uppercase;
            color: $warm-grey;
            margin-bottom: 0;
            margin: 0 20px 0 0;
          }
        }
      }
    }
    .filter-columns {
      margin-top: 23px;
      .column {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .filter-container {
    margin-bottom: 30px;
    .filter-wrapper {
      form {
        .selections {
          display: flex;
          > div {
            margin-top: 0;
          }
        }
      }
      .filter-columns {
        display: flex;
        .column {
          width: 230px;
          margin-bottom: 0;
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .filter-container {
    .filter-wrapper {
      .filter-columns {
        .column {
          width: 356px;
          margin-right: 29px;
        }
      }
    }
  }
}
