.search-result-title {
    margin-top: 28px;
    padding-bottom: 17px!important;
    .title-holder {
        h2 {
            font-size: 24px;
            color: $brownish-grey;
        }
        ul {
            li {
                display: inline-block;
                font-size: 13px;
                color: $warm-grey;
                margin-right: 5px;
                text-transform: uppercase;
                position: relative;
                padding-right: 13px;
                &:last-child {
                    &::after {
                        display: none;
                    }
                }
                &::after {
                    content: '>';
                    color: $warm-grey;
                    display: inline-block;
                    position: absolute;
                    right: 0;
                }
            }
        }
        hr {
            background-color: $cadet-blue !important;
            width: 137px;
            float: left;
            margin: 0;
            height: 2px;
            border-radius: 4px;
            box-shadow: 0 2px 10px 0 rgba(77, 121, 150, 0.08);
            box-sizing: border-box;
        }
    }
    .search-button-holder {
        a {
            span {
                font-size: 14px;
            }
            @include primary-button;
        }
    }
}

@include media-breakpoint-up(md) {
    .search-result-title {
        .title-holder {
            h2 {
                font-size: 28px;
            }
        }
        .search-button-holder {
            a {
                span {
                    font-size: 16px;
                }
                float: right;
                text-align: right;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .search-result-title {
        hr {
            display: none;
        }
    }
}