//popup content
.modal-dialog {
   //position: absolute;
   //top: 10%;
   //left: 10%;
  // transform: translate(-50%, -50%) !important;
  
    &.welcome-popup{
    max-width: 600px;
      .modal-content {
        padding: 20px;
      }
      
      h3{
        text-align: center;
        margin-bottom: 10px;
      }
      p{
        font-size: 14px;
        line-height: 1.5;
        color: #8d8d8d;
        font-weight: 400;
        text-align: center;
      }
      a{
        @include primary-button;
      }
    }
  }