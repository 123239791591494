.source-methology-donate {
  margin-bottom: 50px;
  .container {
    .row {
      .text-block {
        p,
        h2 {
          color: $white;
        }
        h2 {
          font-size: 24px;
          margin-bottom: 22px;
          margin-top: 0;
        }
        p {
          font-size: 16px;
          line-height: 1.6;
        }
        a {
          &.donateBtn {
            margin-top: 28px;
            @include form-button(18px 50px 16px 26px);
            background-color: $black-grey;
            letter-spacing: 1px;
            span{
              &::after{
                right: -24px;
              }
            }
          }
        }
      }
      > div {
        > div {
          padding: 30px;
          background-color: $grass;
          background-image: url($donateBg);
          background-size: cover;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .source-methology-donate {
    margin-bottom: 70px;
    .container {
      .row {
        > div {
          > div {
            padding: 53px 100px 50px 100px;
          }
        }
      }
    }
  }
}
