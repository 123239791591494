body {
  font-family: 'Rubik', sans-serif;
  color: $brownish-grey;
}

// space for header
@include media-breakpoint-down(xl) {
  .App {
    margin-top: $mobile-header-height;
  }
}
@include media-breakpoint-up(xl) {
  .App {
    // min-height: 100vh;  //TODO temporary rule to be removed
    margin-top: $header-height;
  }
}

@keyframes fadingIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  75% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@-webkit-keyframes fadingIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  75% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadingInFast {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@-webkit-keyframes fadingInFast {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

