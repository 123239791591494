.home-search-container {
  padding: 35px 0 60px;
  background-image: url(#{$mainSearch});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  .container {
    .row {
      justify-content: center;
    }
  }
  .error-message-wrapper {
    min-height: 25px;
  }
  .auto-complete{
    .list {
      width: 49%;
    }
  }
}
@include media-breakpoint-up(sm) {
  .home-search-container {
    padding: 75px 0 98px 0;
  }
}
