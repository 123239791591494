@include media-breakpoint-down(md) {
    .footer {
        background-color: $greyish-brown;
    }
    .footer-content {
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .footer-logo {
        margin-bottom: 20px;
        img {
            width: 94px;
            height: 40px;
            object-fit: contain;
        }
    }
    .footer-adresses {
        .footer-adress {
            position: relative;
            margin: 0 0 15px;
            p {
                margin: 0;
                line-height: 20px;
                font-size: 12px;
                letter-spacing: 1px;
                color: $very-light-pink;
            }
            a {
                color: $very-light-pink;
                &:hover {
                    text-decoration: none;
                    color: $white;
                }
            }
        }
    }
    .footer-socail-media {
        // margin-top: 15px;
        p {
            margin-bottom: 15px;
            line-height: 14px;
            font-size: 12px;
            letter-spacing: 1px;
            color: $very-light-pink;
        }
        ul {
            margin: 0;
            padding: 0;
            li {
                display: inline-block;
                margin-right: 30px;
                list-style: none;
                a {
                    display: inline-block;
                    img {
                        height: 20px;
                    }
                }
            }
        }
    }
    .footer-copy-right {
        background-color: $black-grey;
        p {
            margin: 10px 0;
            text-align: center;
            line-height: 12px;
            font-size: 10px;
            letter-spacing: 2px;
            color: $brown-grey;
        }
    }
}

@include media-breakpoint-down(sm) {
    .footer-socail-media {
        margin-top: 15px;
    }
}