.title-button-container {
  margin-top: 19px;
  .title {
    h2 {
      font-size: 24px;
      color: $brownish-grey;
      margin-bottom: 20px;
    }
  }
  .button {
    a {
      @include primary-button-small;
    }
  }
}

@include media-breakpoint-up(md) {
  .title-button-container {
    margin-top: 13px;
    .title {
      h2 {
        font-size: 28px;
        margin-bottom: 0;
        margin-top: 7px;
      }
    }
    .button {
      text-align: right;
    }
  }
}
