.california-tab-content {
    .tab-content{
        .crop-overview-content{
            margin-left: 12px;
            margin-right: 12px;
            .data-table{
                .data-table-heading{
                    padding: 9px 20px;
                }
                .data-table-content{
                    .data-table-key-value{
                        flex: 0 0 100%;
                        border-right: none;
                        > div{
                            &:nth-of-type(1){
                                flex: 0 0 30%;
                            }
                        }
                    }
                }
            }
        }
    }
}