.resources-inner-attachements {
  padding-top: 30px;
  margin-bottom: 30px;
  border-top: 1px solid $very-light-pink;
  h2 {
    margin-bottom: 20px;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 5px;
      list-style: none;
      a {
        @include clearfix;
        position: relative;
        display: block;
        padding: 30px 30px 30px 70px;
        line-height: 28px;
        color: $cadet-blue;
        background-color: $very-light-blue;
        p {
          margin: 0;
          font-size: 20px;
        }
        > span {
          font-size: 13px;
          position: relative;
          &:nth-of-type(1) {
            display: block;
            font-size: 13px;
            line-height: 28px;
          }
          &:nth-of-type(2) {
            &:before {
              @include icons;
              content: '';
              position: absolute;
              top: 4px;
              right: -20px;
              display: inline-block;
              width: 10px;
              height: 12px;
              background-position: -99px 0;
            }
          }
        }
        &:before {
          @include icons;
          content: '';
          position: absolute;
          top: 30px;
          left: 32px;
          display: inline-block;
          width: 21px;
          height: 23px;
          background-position: -327px 0;
        }
        &[href$='.pdf'] {
          &:before {
            background-position: -110px 0;
          }
        }
        &[href$='.docx'],
        &[href$='.doc'] {
          &:before {
            background-position: -177px 0;
          }
        }
        &[href$='.xlsx'],
        &[href$='.xls'] {
          &:before {
            background-position: -133px 0;
          }
        }
        &[href$='.pptx'],
        &[href$='.ppt'] {
          &:before {
            background-position: -155px 0;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .resources-inner-attachements {
    margin-top: 20px;
    margin-bottom: 72px;
    padding-top: 54px;
    ul {
      margin: 0;
      padding: 0;
      li {
        margin-bottom: 5px;
        list-style: none;
        a {
          padding: 30px 50px 30px 70px;
          p {
            max-width: calc(100% - 160px);
            float: left;
            margin: 0;
            font-size: 24px;
          }
          > span {
            font-size: 13px;
            position: relative;
            &:nth-of-type(1) {
              float: left;
              margin-left: 20px;
            }
            &:nth-of-type(2) {
              float: right;
              &:before {
                top: 8px;
              }
            }
          }
        }
      }
    }
  }
}
