.auto-complete {
  position: relative;
  input {
    border-radius: 4px;
    border: solid 1px $very-light-blue;
    padding: 10px 15px;
    width: 100%;
    font-size: 13px;
    height: 35px;
    background-color: $medium-grey;
    &::-webkit-input-placeholder {
      color: $warm-grey;
    }
    &::-moz-placeholder {
      color: $warm-grey;
    }
    &:-ms-input-placeholder {
      color: $warm-grey;
    }
    &:-moz-placeholder {
      color: $warm-grey;
    }
    &::-webkit-search-cancel-button {
      display: none;
      -webkit-appearance: none;
    }
  }
  .list {
    z-index: 10;
    position: absolute;
    top: 35px;
    left: 0;
    right: 0;
    max-height: 150px;
    background: $white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
    overflow-y: auto;
    ul {
      padding: 20px 0;
      li {
        padding: 5px 30px;
        border: none !important;
        line-height: 19px;
        font-size: 16px;
        font-weight: $font-weight-light;
        color: $black-grey;
        &:hover {
          background: $cadet-blue;
          color: $white;
        }
      }
    }
    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px $very-light-blue;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b7c4d2;
      outline: 1px solid $very-light-blue;
    }
  }
}
