.search-result-title {
  @include clearfix;
  padding-bottom: 23px;
  h1 {
    position: relative;
    float: left;
    text-transform: capitalize;
    &:after {
      content: '';
      display: inline-block;
      height: 2px;
      position: absolute;
      left: 0;
      right: -20px;
      bottom: -23px;
      background: $cadet-blue;
    }
  }
  p {
    float: left;
    margin: 0 0 0 10px;
    line-height: 33px;
    font-size: 13px;
    color: $warm-grey;
  }
}
