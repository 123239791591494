.search-result-download {
  position: relative;
  margin-top: 15px;
  margin-bottom: 10px;
  text-align: center;
  > span {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    line-height: 15px;
    font-size: 13px;
    font-weight: $font-weight-medium;
    color: $cadet-blue;
    background-color: transparent;
    border: none;
    box-shadow: none;
    outline: 0;
    cursor: pointer;
    &:after {
      @include icons;
      content: '';
      display: inline-block;
      width: 11px;
      height: 14px;
      position: absolute;
      left: 0;
      top: 3px;
      background-position: -99px 0;
    }
    &::before {
      content: '';
      display: inline-block;
      width: 100%;
      height: 10px;
      position: absolute;
      left: 0;
      top: -7px;
    }
    &:hover {
      ul {
        display: block;
      }
    }
    ul {
      display: none;
      //   z-index: 10;
      position: absolute;
      left: calc(50% - 75px);
      top: -100px;
      width: 155px;
      height: 95px;
      margin: 0;
      padding: 10px 0;
      text-align: left;
      box-shadow: 0 2px 10px 0 rgba(97, 96, 96, 0.3);
      background-color: $white;
      li {
        list-style: none;
        line-height: 15px;
        a {
          display: block;
          padding: 10px 25px;
          line-height: 15px;
          font-size: 13px;
          color: $warm-grey;
          cursor: pointer;
          &:hover {
            text-decoration: none;
            background-color: $cadet-blue;
            color: $white;
          }
        }
      }
      &:after {
        position: absolute;
        bottom: -6px;
        left: calc(50% - 3px);
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 5px 0 5px;
        border-color: #ffffff transparent transparent transparent;
      }
      &:before {
        position: absolute;
        bottom: -8px;
        left: calc(50% - 4px);
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 6px 0 6px;
        border-color: rgba(97, 96, 96, 0.075) transparent transparent
          transparent;
      }
    }
  }
}
