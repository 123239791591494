.common-map-content {
  .text-block {
    padding: 0;
    p {
      font-size: 14px;
      color: $warm-grey;
      line-height: 21px;
      font-weight: $font-weight-normal;
    }
    h2 {
      font-size: 22px;
      color: $brownish-grey;
      font-weight: $font-weight-normal;
    }
  }
  .commonLinkMaps {
    margin: 30px auto;
    text-align: center;
    a:hover {
      text-decoration: none !important;
    }
    a {
      display: block;
      &.map-url {
        color: $cadet-blue;
        display: inline-block;
        justify-content: center;
        font-size: 14px;
        font-weight: $font-weight-medium;
        margin: 30px 0;
        &:hover{
            opacity: 0.6;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .common-map-content {
    .results-side-bar{
      margin-top: 71px;
    }
    .text-block {
      h2 {
        font-size: 28px;
      }
    }
  }
}
