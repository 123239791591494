.pagination {
  margin: 13px 0 10px;
  justify-content: center;
  border-radius: 0;
  flex-wrap: wrap;
  li.page-item {
    border-radius: 0;
    outline: 0;
    a.page-link {
      margin: 0 1px 10px;
      padding: 0;
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 13px;
      text-align: center;
      color: $warm-grey;
      background-color: #f1f6fb;
      border: 1px solid transparent;
      border-radius: 0;
      &[aria-label='Next'],
      &[aria-label='Previous'] {
        color: #2f2f2f;
        font-weight: $font-weight-bold;
        font-weight: 400;
        font-size: 16px;
      }
      &:hover {
        background-color: #33556b;
        color: $white;
      }
    }
    &.disabled {
      opacity: 0.8;
      border-color: #f1f6fb;
      cursor: not-allowed;
      a.page-link {
        border-radius: 0;
        background-color: #f1f6fb;
      }
    }
    &.active {
      pointer-events: none;
      a {
        background-color: #33556b;
        color: $white;
      }
    }
  }
}
