@mixin button-styles($padding: 16.5px 41px 16.5px 26.5px) {
  display: inline-block;
  padding: $padding;
  background-color: $white;
  font-weight: $font-weight-medium;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;
  cursor: pointer;
  span {
    position: relative;
    &:after {
      @include icons;
      content: '';
      display: inline-block;
      position: absolute;
    }
  }
  &:hover {
    text-decoration: none;
    color: $white;
  }
}

@mixin primary-button($padding: 16.5px 41px 16.5px 26.5px) {
  @include button-styles($padding);
  border: 1px solid $cadet-blue;
  border-radius: 24.5px;
  color: $cadet-blue;
  line-height: 14px;
  font-size: 16px;
  span {
    &:after {
      width: 12px;
      height: 9px;
      right: -18px;
      top: calc(50% - 4.5px);
      background-position: 0 0;
    }
  }
  &:hover {
    background-color: $cadet-blue;
    span {
      &:after {
        background-position: -13px 0;
      }
    }
  }
}

@mixin primary-button-small(
  $padding: 12px 30px 12px 15px,
  $color: $cadet-blue
) {
  @include button-styles($padding);
  border: 1px solid $color;
  border-radius: 24.5px;
  color: $color;
  line-height: 14px;
  font-size: 12px;
  span {
    &:after {
      width: 12px;
      height: 9px;
      right: -18px;
      top: calc(50% - 4.5px);
      background-position: 0 0;
    }
  }
  &:hover {
    background-color: $color;
    span {
      &:after {
        background-position: -13px 0;
      }
    }
  }
}

@mixin secondary-button($padding: 15px 29px 11px 14px) {
  @include button-styles($padding);
  border: 1px solid $very-light-blue;
  border-radius: 19px;
  color: $salmon;
  line-height: 14px;
  font-size: 12px;
  span {
    &:after {
      width: 10px;
      height: 8px;
      right: -15px;
      top: calc(50% - 4px);
      background-position: 0 -11px;
    }
  }
  &:hover {
    background-color: $salmon;
    span {
      &:after {
        background-position: -12px -11px;
      }
    }
  }
}

@mixin form-button($padding: 16px 60px 14px 38px) {
  @include button-styles($padding);
  position: relative;
  width: 170px;
  border: 1px solid transparent;
  border-radius: 24.5px;
  color: $white;
  line-height: 14px;
  font-size: 16px;
  background-color: $black-grey;
  span {
    &:after {
      width: 12px;
      height: 9px;
      right: -18px;
      top: calc(50% - 4.5px);
      background-position: -13px 0;
    }
  }
  &:hover {
    background-color: $brownish-grey;
    color: $white;
    span {
      &:after {
        background-position: -13px 0;
      }
    }
  }
}

@mixin custom-form-button($padding: 16px 60px 14px 38px, $color: $cadet-blue) {
  @include button-styles($padding);
  position: relative;
  min-width: 128px;
  border: 1px solid transparent;
  border-radius: 24.5px;
  color: $white;
  line-height: 14px;
  font-size: 13px;
  background-color: $color;
  border: solid 1px transparent;
  span {
    &:after {
      width: 12px;
      height: 9px;
      right: -18px;
      top: calc(50% - 4.5px);
      background-position: -13px 0;
    }
  }
  &:hover {
    background-color: $white;
    border: solid 1px $cadet-blue;
    color: $color;
    span {
      &:after {
        background-position: 0 0;
      }
    }
  }
}

@mixin button-transparent($padding: 10px 20px 40px 10px, $color: $cadet-blue) {
  @include button-styles($padding);
  position: relative;
  min-width: 128px;
  border: 1px solid transparent;
  border-radius: 24.5px;
  color: $white;
  line-height: 14px;
  font-size: 13px;
  background-color: $color;
  border: solid 1px $white;
  span {
    &:after {
      background-image: none;

    }
  }
  &:hover {
    background-color: $white;
    border: solid 1px $white;
    color: $brownish-grey;
    span {
      &:after {
        @include icons;
        content: '';
        width: 10px;
        height: 7px;
        display: inline-block;
        position: absolute;
        background-position: -182px -54px;
        top: 4px;
        margin-left: 10px;
      }
    }
  }
}
