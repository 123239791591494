.faq-content {
  padding: 30px 0 50px;
  .row {
    &:nth-of-type(1) {
      h2 {
        margin-bottom: 30px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .faq-content {
    padding: 17px 0 65px;
    .row {
      &:nth-of-type(1) {
        h2 {
          font-size: 28px;
          margin-bottom: 52px;
        }
      }
    }
  }
}
