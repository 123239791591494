.common-inner-related-articles {
    margin: 10px auto 40px;
    h2 {
        margin-bottom: 24px;
        line-height: 28px;
        text-align: center;
    }
}

@include media-breakpoint-up(xl) {
    .common-inner-related-articles {
        margin: 0 auto 68px;
        padding: 0;
        h2 {
            margin-bottom: 48px;
        }
    }
}