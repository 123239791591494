.california-tab-content {
  &.shortcut-detail-content {
    .tab-content {
      .search-result-table {
        &.top-fifty-pesticides {
          .result-table {
            .result-table-row {
              &.titles {
                > div {
                  > div {
                    &:nth-of-type(7) {
                      flex: 0 0 103px;
                    }
                  }
                }
              }
            }
          }
        }
        .result-table {
          width: 856px;
        }
      }
    }
  }
  .nav-tabs {
    li {
      &.nav-item {
        min-width: 100%;
      }
    }
  }
  .tab-content {
    padding: 30px 10px 8px 10px;
    .no-results {
      text-align: center;
      font-size: 14px;
      margin-top: 10px;
      color: $brownish-grey;
    }
    .search-result-table {
      margin-top: 0;
      overflow-y: auto;
      .result-table {
        border-bottom: none;
        width: 830px;
        padding: 0 10px;

        .result-table-row {
          &.titles {
            > div {
              > div {
                text-transform: none;
                padding: 16px 12px;
                cursor: default;
                &::after {
                  display: none;
                }
              }
            }
          }
          a {
            > div {
              padding: 14px 13px;
              span {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                word-break: break-all;
              }
            }
          }
        }
      }
    }
  }
  .button-holder {
    text-align: center;
    border-bottom: solid 1px $very-light-pink;
    padding: 8px 0;
    width: 100%;
    .viewmore {
      color: $cadet-blue;
      text-transform: uppercase;
      font-weight: $font-weight-medium;
      text-decoration: none;
      position: relative;
      background: transparent;
      font-size: 13px;
      border: none;
      padding: 10px 0;
      &:hover {
        opacity: 0.6;
      }

      &::before {
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        @include icons;
        background-position: -93px -16px;
        left: -20px;
        top: 12px;
      }
    }
  }
  .search-result-download {
    margin-bottom: 0;
    margin-top: 11px;
  }
  .inner-upper-container {
    .title-container {
      display: block;
      align-items: center;
      word-break: break-all;
      h2 {
        font-size: 24px;
        margin-right: 12px;
      }
      span {
        font-size: 13px;
        color: $warm-grey;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .california-tab-content {
    &.shortcut-detail-content {
      .inner-upper-container {
        .title-container {
          flex: 0 0 100%;
          flex-direction: initial;
          align-items: center;
        }
      }
    }
    .inner-upper-container {
      .title-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        // flex: 0 0 100%;
        h2 {
          font-size: 28px;
        }
        span {
          display: block;
          margin-top: 3px;
        }
      }
    }
    .nav-tabs {
      li {
        &.nav-item {
          min-width: auto;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .california-tab-content {
    &.shortcut-detail-content {
      .tab-content {
        .search-result-table {
          &.top-fifty-pesticides {
            .result-table {
              .result-table-row {
                &.titles {
                  > div {
                    > div {
                      &:nth-of-type(1) {
                        flex: 0 0 132px;
                      }
                      &:nth-of-type(2) {
                        flex: 0 0 119px;
                      }
                      &:nth-of-type(3) {
                        flex: 0 0 111px;
                      }
                      &:nth-of-type(4) {
                        flex: 0 0 84px;
                      }
                      &:nth-of-type(5) {
                        flex: 0 0 113px;
                      }
                      &:nth-of-type(6) {
                        flex: 0 0 114px;
                      }
                      &:nth-of-type(7) {
                        flex: 0 0 103px;
                      }
                    }
                  }
                }
                a {
                  > div {
                    &:nth-of-type(1) {
                      flex: 0 0 132px;
                    }
                    &:nth-of-type(2) {
                      flex: 0 0 119px;
                    }
                    &:nth-of-type(3) {
                      flex: 0 0 111px;
                    }
                    &:nth-of-type(4) {
                      flex: 0 0 84px;
                    }
                    &:nth-of-type(5) {
                      flex: 0 0 113px;
                    }
                    &:nth-of-type(6) {
                      flex: 0 0 114px;
                    }
                  }
                }
              }
            }
          }
          .result-table {
            width: auto;
          }
        }
      }
    }
    .tab-content {
      .search-result-table {
        .result-table {
          width: auto;
        }
      }
    }
  }
}
