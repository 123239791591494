.breadcrumb {
  background: transparent;
  padding: 18px 0 20px 0;
  margin-bottom: 0;
  a {
    font-size: 12px;
    font-weight: $font-weight-medium;
    letter-spacing: 1px;
    color: $cadet-blue;
    opacity: 0.5;
    &.breadcrumb-item {
      padding-left: 0;
      &:not(:last-of-type) {
        padding-right: 7px;
        &:after {
          display: inline-block;
          margin-left: 7px;
          content: '>';
          opacity: 0.8;
          color: $medium-navy-blue;
        }
      }
      &:before {
        display: none;
      }
    }
    &.active {
      opacity: 1;
      color: $medium-navy-blue;
    }
    &.disable {
      color: $cadet-blue;
      opacity: 0.5;
      pointer-events: none;
      &:hover {
        // opacity: 0.5;
      }
    }
    &:hover {
      text-decoration: none;
      opacity: 1;
    }
    &:last-of-type {
      opacity: 1;
      &.disable {
        opacity: 0.5;
      }
    }
  }
}

.darkBreadcrumb {
  position: absolute;
  .breadcrumb {
    a {
      color: $white;
      &.breadcrumb-item {
        &:not(:last-of-type) {
          &:after {
            color: $white;
          }
        }
        &:before {
          display: none;
        }
      }
      &.active,
      &:last-of-type {
        color: $white;
      }
    }
  }
}
.detail-page {
  .breadcrumb {
    a {
      color: $medium-blue;
    }
  }
}
