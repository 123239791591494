.contactform-content{
  .row {
      h2 {
        margin-bottom: 15px;
      }
      .contactform{
        margin: auto;
        input[type=text],input[type=email], textarea {
          width: 100%;
          border: 1px solid #e4edf7;
          padding: 8px 15px;
          background-color: #fff;
          margin: 12px;
          font-weight: 300;
          font-size: 13px;
          line-height: normal;

        }
       button{
          display: inline-block;
          padding: 11px 30px 11px 16px;
          font-weight: 500;
          text-transform: uppercase;
          min-width: 128px;
          border-radius: 24.5px;
          margin: 12px;
          color: #fff;
          line-height: 14px;
          font-size: 13px;
          background-color: #4d7996;
          border: 1px solid transparent;

        }
      }
  }
}

@include media-breakpoint-up(md) {
  // background-color: red;
  .contactform-content {
    padding: 17px 0 65px;
    .row {
      &:nth-of-type(1) {
        h2 {
          font-size: 28px;
          margin-bottom: 30px;
        }
      }
    }
  }
}
