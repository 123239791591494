.icon-wrapper {
  padding-bottom: 50px;
  .icon-holder {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .icon-button-wrap {
      &:nth-of-type(1) {
        img {
          height: 80px;
        }
      }
      &:nth-of-type(2) {
        img {
          height: 80px;
        }
      }
      text-align: center;
      img {
        margin-bottom: 30px;
      }
      a {
        &.button {
          min-width: 170px;
          @include primary-button;
          display: block;
          span {
            &::after {
              right: -17px;
              top: 6px;
            }
          }
        }
      }
    }
    > span {
      margin: 0 8px;
      font-size: 10px;
      color: $cadet-blue;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      margin-bottom: 16px;
    }
  }
}

@include media-breakpoint-down(xs) {
  .icon-wrapper {
    .icon-holder {
      .icon-button-wrap {
        a {
          min-width: 156px;
          padding: 16.5px 38px 16.5px 20.5px;
        }
      }
    }
  }
}
