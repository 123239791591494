.search {
  input {
    &[type='search'] {
      width: 100%;
      padding: 26px 60px 26px 29px;
      background-color: $white;
      border: 1px solid transparent;
      border-radius: 38.5px;
      font-weight: $font-weight-light;
      font-size: 13px;
      line-height: normal;
      background-image: url($search-icon);
      background-repeat: no-repeat;
      background-position: top 50% right 28px;
      background-size: 5%;
      &::-webkit-input-placeholder {
        color: $brown-grey;
        font-weight: $font-weight-light;
      }
      &::-moz-placeholder {
        color: $brown-grey;
        font-weight: $font-weight-light;
      }
      &:-ms-input-placeholder {
        color: $brown-grey;
        font-weight: $font-weight-light;
      }
      &:-moz-placeholder {
        color: $brown-grey;
        font-weight: $font-weight-light;
      }
      &::-webkit-search-cancel-button {
        display: none;
        -webkit-appearance: none;
      }
    }
  }
  .search-button{
    top: 0;
    right: 0;
    display: inline-block;
    position: absolute;
    padding: 26px 60px 26px 29px;
    width: 20px;
    z-index: 1;
    border: 0;
    background-image: none;
    background: 0 0;  
  }
  .bottom-elements {
    text-align: right;
    padding-top: 15px;
    display: block;
    text-align: center;
    label {
      margin-bottom: 0;
      font-size: 12px;
      text-transform: uppercase;
      color: $white;
      vertical-align: top;
      padding-top: 1px;
      margin-right: 20px;
      line-height: 20px;
    }
    a {
      &.icon-link {
        font-size: 12px;
        text-transform: uppercase;
        color: $white;
        padding-left: 27px;
        position: relative;
        &::after {
          @include icons;
          content: '';
          display: block;
          width: 16px;
          height: 16px;
          background-position: -26px 0;
          background-repeat: no-repeat;
          left: 0;
          top: 0;
          position: absolute;
        }
        &:hover {
          text-decoration: none;
          color: $cadet-blue;

          &::after {
            background-position: -44px 0;
          }
        }
      }
    }

    input {
      &[type='radio'] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: inline-block;
        position: relative;
        background-color: $white;
        color: #666;
        height: 20px;
        width: 20px;
        border: 0;
        border-radius: 10px;
        cursor: pointer;
        margin-right: 11px;
        outline: none;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.11);
        border: 1px solid transparent;
      }
      &[type='radio']:checked::before {
        @include icons;
        position: absolute;
        width: 18px;
        height: 18px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: 0 auto;
        background-position: -145px -25px;
        content: '';
        border-radius: 10px;
        border-color: #e1e1e1;
      }
    }
  }
  &.error,
  &.error.search-result-page {
    .error-message {
      padding: 0 30px 10px;
      color: $error;
      font-size: 12px;
      font-weight: $font-weight-medium;
      line-height: 14px;
    }
    input {
      &[type='search'] {
        border-color: $error;
      }
    }
  }
  &.search-result-page {
    input {
      &[type='search'] {
        // width: 100%;
        // padding: 14px 65px 14px 29px;
        // background-color: $white;
        border: 1px solid #e1e1e1;
        // border-radius: 38.5px;
        // font-weight: $font-weight-light;
        // font-size: 13px;
        // line-height: normal;
        // background-image: url($search-icon);
        // background-repeat: no-repeat;
        // background-position: top 50% right 28px;
        // background-size: 5%;
        // &::-webkit-input-placeholder {
        //   color: $brown-grey;
        //   font-weight: $font-weight-light;
        // }
        // &::-moz-placeholder {
        //   color: $brown-grey;
        //   font-weight: $font-weight-light;
        // }
        // &:-ms-input-placeholder {
        //   color: $brown-grey;
        //   font-weight: $font-weight-light;
        // }
        // &:-moz-placeholder {
        //   color: $brown-grey;
        //   font-weight: $font-weight-light;
        // }
        // &::-webkit-search-cancel-button {
        //   display: none;
        //   -webkit-appearance: none;
        // }
      }
    }
    .bottom-elements {
      @include clearfix;
      float: right;
      padding-top: 15px;
      display: block;
      text-align: center;
      label {
        float: left;
        // margin-bottom: 0;
        // font-size: 12px;
        // text-transform: uppercase;
        color: $brownish-grey;
        // vertical-align: top;
        // padding-top: 1px;
        // margin-right: 20px;
      }
      a {
        float: left;
        &.icon-link {
          // font-size: 12px;
          // text-transform: uppercase;
          color: $brownish-grey;
          // padding-left: 27px;
          // position: relative;
          &::after {
            // @include icons;
            // content: '';
            // display: block;
            // width: 16px;
            // height: 16px;
            background-position: -44px 0;
            // background-repeat: no-repeat;
            // left: 0;
            // top: 0;
            // position: absolute;
          }
          &:hover {
            // text-decoration: none;
            // color: $cadet-blue;

            &::after {
              background-position: -44px 0;
            }
          }
        }
      }

      input {
        float: left;
        &[type='radio'] {
          // -webkit-appearance: none;
          // -moz-appearance: none;
          // appearance: none;
          // display: inline-block;
          // position: relative;
          // background-color: $white;
          // color: #666;
          // height: 20px;
          // width: 20px;
          // border: 0;
          // border-radius: 10px;
          // cursor: pointer;
          // margin-right: 11px;
          // outline: none;
          border: 1px solid #e1e1e1;
        }
        &[type='radio']:checked::before {
          // position: absolute;
          // width: 16px;
          // height: 16px;
          // left: 0;
          // right: 0;
          // top: 2px;
          // bottom: 0;
          // margin: 0 auto;
          // background-color: $cadet-blue;
          // content: '';
          // border-radius: 10px;
          // background-position: -145px -25px;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .search {
    input {
      &[type='search'] {
        // padding: 21px 41px 21px 30px;
        font-size: 16px;
        background-size: auto;
      }
    }

    .bottom-elements {
      padding-right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        &.icon-link {
          &::after {
            top: 2px;
          }
        }
      }
      label {
        padding-top: 0;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .search {
    input {
      &[type='search'] {
        // padding: 14px 29px;
      }
    }
    .bottom-elements {
      padding-right: 30px;
      justify-content: flex-end;
    }
  }
}

@include media-breakpoint-down(lg) {
  .search {
    &.search-result-page {
      margin-top: 15px;
      .bottom-elements {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
        a {
          &.icon-link {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
