.common-inner-header {
  h1 {
    margin-top: 14px;
    margin-bottom: 25px;
    text-align: center;
  }
  .blog-inner-date {
    p {
      margin: 17px 0 0;
      line-height: 30px;
      font-family: $secondary-font;
      font-size: 16px;
      color: $brownish-grey;
    }
  }
  .blog-inner-social-media {
    ul {
      margin: 5px 0 0;
      padding: 0;
      display: flex;
      align-items: center;
      li {
        margin-left: 10px;
        list-style: none;
        img {
          width: 30px;
          height: 30px;
          margin-bottom: 0;
        }
        &:hover {
          opacity: 0.6;
        }
        button.react-share__ShareButton {
          @include icons;
          width: 30px;
          height: 30px;
          &[aria-label='email'] {
            background-position: -200px 0 !important;
          }
          &[aria-label='facebook'] {
            background-position: -232px 0 !important;
          }
          &[aria-label='twitter'] {
            background-position: -264px 0 !important;
          }
          &[aria-label='linkedin'] {
            background-position: -296px 0 !important;
          }
        }
      }
    }
  }
  .resource-inner-social-media {
    display: flex;
    justify-content: flex-end;
    ul {
      margin: 11px 0 5px;
      padding: 0;
      display: flex;
      align-items: center;
      li {
        margin-left: 10px;
        list-style: none;
        img {
          width: 30px;
          height: 30px;
          margin-bottom: 0;
        }
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
  > div {
    > img {
      width: 100%;
    }
  }
}
@include media-breakpoint-up(md) {
  .common-inner-header {
    h1 {
      margin-bottom: 50px;
    }
  }
}
