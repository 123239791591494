.custom-accordion {
  background-color: $white-grey;
  .accordion-title {
    position: relative;
    padding: 25px 60px 25.5px 30px;
    margin-bottom: 2px;
    font-size: 15px;
    line-height: 24px;
    color: $medium-brown-grey;
    font-family: $secondary-font;
    background-color: $very-light-pink;
    &:after {
      @include icons;
      position: absolute;
      right: 29.5px;
      top: 33px;
      content: '';
      display: inline-block;
      width: 17px;
      height: 10px;
      background-position: -81px 0;
    }
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: $medium-pink;
      transition: all 0.2s ease-in-out;
    }
    &.open {
      background-color: transparent;
      color: $cadet-blue;
      &:after {
        background-position: -62px 0;
      }
    }
  }
  .accordion-item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
    .accordion-content {
      padding: 0 30px 31px 30px;
      margin-top: 2px;
      p,
      ul li,
      table tr,
      ol li {
        font-size: 14px;
      }
      ul {
        margin-bottom: 15px;
        margin-left: 30px;

        li {
          list-style: none;
          position: relative;
          padding-left: 25px;
          &::before {
            content: '';
            display: inline-block;
            position: absolute;
            left: 0;
            top: 8px;
            border-radius: 100%;
            background: $brown-grey;
            width: 3px;
            height: 3px;
            color: $brown-grey;
          }
        }
      }
      ol {
        margin-bottom: 15px;
        margin-left: 30px;
        padding-left: 14px;
        li {
          padding-left: 13px;
        }
      }
      .arrow-right {
        position: relative;
        &:after {
          content: '';
          width: 12px;
          height: 9px;
          left: 0;
          top: 12px;
          background-position: 0 0;
          position: absolute;
          @include icons;
        }
      }
      table {
        margin-top: 19px;
        margin-bottom: 0;
        min-width: 600px;

        tr {
          &:first-child {
            height: auto !important;
            td {
              color: $white;
              text-transform: uppercase;
              background-color: $dark-navy-blue;
              font-weight: $font-weight-medium;
              border: none;
              font-size: 13px;
              vertical-align: top;
              height: auto !important;
            }
          }
          th {
            color: $white;
            text-transform: uppercase;
            background-color: $dark-navy-blue;
            font-weight: $font-weight-medium;
            padding: 7px 20px;
            border: none;
            font-size: 13px;
            vertical-align: top;
          }
          td {
            border: solid 1px $very-light-blue;
            font-size: 13px;
            padding: 17px 20px 18px 20px;
            line-height: 1;
            height: auto !important;
          }
        }
      }
      div * {
        line-height: 21px;
        color: $brown-grey;
        font-weight: $font-weight-normal;
      }
    }
    &.collapsed {
      max-height: 0;
      transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
    }
  }
}

@include media-breakpoint-up(md) {
  .custom-accordion {
    .accordion-title {
      font-size: 20px;
    }
    .accordion-item {
      .accordion-content {
        ul {
          padding-left: 0;
          li {
            padding-left: 27px;
          }
        }
        table {
          table-layout: fixed !important;
          min-width: initial;
          margin-top: 34px;
        }
        a {
          color: $cadet-blue;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .custom-accordion {
    .accordion-item {
      .accordion-content {
        .arrow-right {
          display: none;
        }
      }
    }
  }
}
