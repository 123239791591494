.blog-inner-header {
  h1 {
    margin: 20px 0 0 0;
  }
  .text-block {
    padding: 0;
  }
  figure {
    padding: 0 15px;
    margin-top: 23px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .blog-inner-date {
    text-align: center;
    margin-top: 26px;

    p {
      line-height: 0;
    }
  }
  .blog-inner-social-media {
    float: right;
    ul {
      margin: -6px 0 8px;
    }
  }

  .blog-description {
    .detail-page-content {
      p {
        a {
          color: #4d7996;
          font-weight: 500;
          transition: all 0.3s ease-in-out;
          &:hover {
            text-decoration: none;
            opacity: 0.8;
          }
        }
      }
    }
  }

  .detail-page-content {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ul,
    ol {
      margin-left: 100px;
      margin-right: 100px;
    }
    blockquote {
      p {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

@include media-breakpoint-between(sm, lg) {
  .blog-inner-header {
    .blog-image {
      padding-top: 60px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .blog-inner-header {
    .blog-image {
      padding-top: 30px;
    }
    figure {
      img {
        height: auto;
      }
    }
    .detail-page-content {
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      ul {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .blog-inner-header {
    .text-block {
      padding: 0 0 28px !important;
    }
    figure {
      width: 100%;
      height: 611px;
      img {
        object-fit: cover;
      }
    }
    .blog-image {
      padding: 0;
      margin-top: 14px;
      margin-bottom: 18px;
    }
    h3 {
      font-size: 24px;
    }
  }
}
