$header-height: 94px;
$mobile-header-height: 77px;
$footer-height: 173px;
$mobile-footer-height: 173px;

$primary-font: 'Rubik', sans-serif;
$secondary-font: 'Roboto', sans-serif;

$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-normal: 400;
$font-weight-light: 300;

$brown-grey: #8d8d8d;
$very-light-pink: #f1f1f1;
$medium-pink: #e6e6e6;
$brownish-grey: #616060;
$white-grey: #fcfcfc;
$cadet-blue: #4d7996;
$very-light-blue: #e4edf7;
$grass: #65b727;
$black-grey: #2f2f2f;
$salmon: #fa6c6c;
$white: #ffffff;
$light-grey: #e4ecf2;
$greyish-brown: #565656;
$very-light-grey: #f3f3f3;
$medium-brown-grey: #626060;
$navy-blue: #007fa3;
$dark-navy-blue: #33556b;
$medium-navy-blue: #177c9c;
$warm-grey: #8d8d8d;
$light-blue: #158bd9;
$deep-sky-blue: #0083fe;
$medium-blue: #008dc1;
$pale-grey: #f1f6fb;
$medium-grey: #f8fbff;
$black: #000000;

$primary-link-color: $cadet-blue;

$error: #fa6c6c;

$footer-text-color: #cccccc;

//Image Path
$image-base-path: '../../images/';

$icon-sprite: './../../images/icons/icons.svg';

//Background Images
$mainSearch: '../../../images/background/search-bg.png';
$donateBg: '../../../images/background/donate-bg.png';
$innerBg: '../../../images/background/inner-bg.png';
$placeholderBg: '../../../images/background/placeholder.png';
$blogFormBg: '../../../images/background/blog-form.jpg';
$select-icon: '../../../images/icons/select.svg';
$select-fill-icon: '../../../images/icons/select-fill.svg';
$badactor: '#{$image-base-path}icons/badactor.svg';
$highhazard: '../../../images/icons/highhazaradous.svg';
$california-search-bg: '#{$image-base-path}/background/california-search-container.png';

//Icons
$search-icon: '#{$image-base-path}icons/search.svg';
$search-small: '#{$image-base-path}icons/search-small.svg';
$star-search-icon: '#{$image-base-path}icons/star-search.svg';
