.country-popup {
  cursor: pointer;
  z-index: 2;
  position: absolute;
  top: 625px;
  left: 0;
  width: 236px;
  padding: 15px;
  box-shadow: 0 0 15px 5px rgba(47, 47, 47, 0.3);
  background-color: #ffffff;

  > div {
    &:nth-of-type(1) {
      > div {
        &:nth-of-type(1) {
          position: relative;
          padding-bottom: 10px;
          padding-right: 25px;
          border-bottom: 1px solid $very-light-blue;
          line-height: 18px;
          font-size: 15px;
          font-weight: $font-weight-medium;
          color: $brownish-grey;
          text-transform: uppercase;

          span {
            position: absolute;
            right: 0;
            top: 0;
            line-height: 18px;
            font-family: $secondary-font;
            font-size: 16px;
            text-align: right;
            color: $brownish-grey;
          }
        }
        &:nth-of-type(2) {
          position: relative;
          padding-top: 10px;
          padding-right: 25px;
          padding-bottom: 5px;
          line-height: 15px;
          font-size: 13px;
          color: $warm-grey;

          span {
            position: absolute;
            right: 0;
            top: 10px;
            line-height: 15px;
            font-size: 13px;
            text-align: right;
            color: $warm-grey;
          }
        }

        &:nth-of-type(3) {
          position: relative;
          padding-top: 5px;
          padding-bottom: 10px;
          padding-right: 25px;
          border-bottom: 1px solid $very-light-blue;
          line-height: 15px;
          font-size: 13px;
          color: $warm-grey;

          span {
            position: absolute;
            right: 0;
            top: 5px;
            line-height: 15px;
            font-size: 13px;
            text-align: right;
            color: $warm-grey;
          }
        }
      }
    }

    &:nth-of-type(2) {
      > div > div {
        &:nth-of-type(1) {
          padding: 15px 0 16px;
          line-height: 18px;
          font-size: 15px;
          font-weight: 500;
          color: $brownish-grey;
        }

        &:nth-of-type(2) {
          height: 160px;
          overflow-y: auto;
          overflow-x: hidden;

          ul {
            li {
              padding: 4px 10px 4px 0;
              line-height: 15px;
              font-size: 13px;
              color: $warm-grey;
            }
          }

          &::-webkit-scrollbar {
            width: 3px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #cccccc;
          }
        }
      }
    }
  }

  > span {
    position: absolute;
    right: -10px;
    top: -10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $black-grey;
    cursor: pointer;

    &::after {
      content: '';
      @include icons;
      position: absolute;
      right: 5px;
      top: 5px;
      width: 9px;
      height: 9px;
      background-position: -38px -60px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .country-popup {
    left: auto !important;
    right: 20px;
    top: 30%;
    > span {
      display: none;
    }
  }
}
