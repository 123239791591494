.global-ban-map-data {
  .global-ban-map-content {
    &.text-block {
      padding-top: 0;
      padding-bottom: 20px;
      h2 {
        font-size: 22px;
        color: $brownish-grey;
        font-weight: $font-weight-normal;
      }
      p {
        font-size: 14px;
        color: $warm-grey;
        line-height: 21px;
        font-weight: $font-weight-normal;
      }
    }
  }
  .map-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    svg {
      > g {
        > g {
          > g {
            > g {
              path {
                pointer-events: none;
              }
              path[fill='#f3ec54'] {
                pointer-events: auto;
              }
            }
          }
        }
      }
    }
    &.zoom-0 {
      > div {
        // width: 100%;
        // height: 500px;
        > div {
          // width: 100%;
          // height: 500px;
          > div {
            // width: 100%;
            // height: 500px;
            > div {
              // width: 100%;
              // height: 500px;
              > svg {
                width: 100%;
                height: 500px;
              }
            }
          }
        }
      }
    }
    &.zoom-1 {
      > div {
        // width: 1000px;
        // height: 600px;
        > div {
          width: 1000px;
          height: 600px;
          > div {
            width: 1000px;
            height: 600px;
            > div {
              width: 1000px;
              height: 600px;
              > svg {
                width: 1000px;
                height: 600px;
              }
            }
          }
        }
      }
    }
    &.zoom-2 {
      > div {
        // width: 1200px;
        // height: 700px;
        > div {
          width: 1200px;
          height: 700px;
          > div {
            width: 1200px;
            height: 700px;
            > div {
              width: 1200px;
              height: 700px;
              > svg {
                width: 1200px;
                height: 700px;
              }
            }
          }
        }
      }
    }
    &.zoom-3 {
      > div {
        // width: 1500px;
        // height: 1000px;
        > div {
          width: 1500px;
          height: 1000px;
          > div {
            width: 1500px;
            height: 1000px;
            > div {
              width: 1500px;
              height: 1000px;
              > svg {
                width: 1500px;
                height: 1000px;
              }
            }
          }
        }
      }
    }
    // path {
    //   stroke-width: 1; /* control the countries borders width */
    //   stroke: $white; /* choose a color for the border */
    //   &:hover {
    //     stroke: $cadet-blue;
    //   }
    // }
  }
  .logo-items {
    padding-bottom: 20px;
    h3 {
      margin-bottom: 11px;
      font-size: 12px;
      text-align: center;
      color: $brownish-grey;
      font-weight: $font-weight-medium;
    }
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        list-style: none;
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
        img {
          height: 40px;
        }
      }
    }
    .presented-by {
      float: left;
      width: 25%;
      padding-left: 63px;
    }
    .map-data-courtesy {
      float: left;
      width: 75%;
      padding-left: 133px;
    }
  }
  .map-filters {
    margin-bottom: 10px;
    .filter-holder {
      span {
        display: inline-block;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: $font-weight-medium;
        color: $black-grey;
        opacity: 0.5;
        display: block;
        text-align: center;
      }
      > div > div {
        width: 100%;
        margin-bottom: 20px;
        > div {
          &:nth-of-type(1) {
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: solid 1px $very-light-blue;
            border-radius: 0;
            box-shadow: none !important;
            min-height: 43px;
            > div {
              &:nth-of-type(1) {
                padding: 0;
                position: relative;
                &::after {
                  content: '';
                  position: absolute;
                  @include icons;
                  width: 18px;
                  height: 17px;
                  background-position: -111px -25px;
                  right: 18px;
                  top: 6px;
                }
                > div {
                  font-size: 13px;
                  color: $brownish-grey;
                  line-height: 20px;
                  &.css-1uccc91-singleValue {
                    color: $black !important;
                    padding-right: 30px;
                  }
                  > div {
                    input {
                      color: $black !important;
                    }
                  }
                }
              }
              &:nth-of-type(2) {
                display: none;
              }
            }
          }
          &:nth-of-type(2) {
            margin-top: 0;
            > div {
              > div {
                font-size: 13px;
                color: $brownish-grey;
                background: transparent;
                &:hover {
                  color: $white;
                  background-color: $cadet-blue;
                }
              }
            }
          }
        }
      }
    }
  }
  .map-widget-container {
    text-align: right;
    position: relative;
    margin-bottom: 30px;
    margin-top: 20px;
    .iframe-holder {
      position: absolute;
      background-color: $white;
      width: 100%;
      height: 71px;
      right: 0;
      padding: 0 20px 10px 20px;
      border: 1px solid $medium-pink;
      border-radius: 4px;
      top: -71px;
      display: none;
      &.active {
        display: block;
      }
      textarea {
        width: 100%;
        font-size: 13px;
        border: none;
        resize: none;
        color: $brownish-grey;
        margin-top: 14px;
        &:focus {
          border: none;
          outline: none;
        }
      }
      .close {
        position: absolute;
        top: 2px;
        right: 12px;
        cursor: pointer;
        color: $brownish-grey;
        font-size: 13px;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    .map-widget {
      span {
        font-size: 13px;
        color: $cadet-blue;
        font-weight: $font-weight-medium;
        text-transform: uppercase;
        cursor: pointer;
        position: relative;
        padding-left: 20px;
        &:hover {
          opacity: 0.7;
        }
        &::after {
          content: '';
          position: absolute;
          @include icons;
          width: 16px;
          height: 16px;
          background-position: -254px -33px;
          left: 0;
          top: 0px;
        }
      }
      &.close {
        position: absolute;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .map-wrapper {
    min-height: 500px;
  }
  .global-ban-map-data {
    .results-side-bar {
      margin-top: 127px;
    }
    .global-ban-map-content {
      &.text-block {
        h2 {
          font-size: 28px;
        }
      }
    }
    .logo-items {
      margin-top: 60px;
      margin-bottom: 120px;
      ul {
        li {
          margin-right: 50px;
          img {
            height: 64px;
          }
        }
      }
    }
    .map-filters {
      margin-bottom: 10px;
      margin-top: 30px;
      .filter-holder {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          margin: 0 25px 20px;
        }
        > div {
          width: 214px;
          margin-bottom: 0;
        }
      }
    }
    .map-widget-container {
      .iframe-holder {
        width: 530px;
      }
    }
    &.embed {
      margin: -50px 0 100px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .map-wrapper {
    > div {
      width: calc(100% - 30px) !important;
    }
    &.zoom-0 {
      > div {
        // width: 100%;
        // height: 500px;
        > div {
          width: 100%;
          height: 300px !important;
          > div {
            width: 100%;
            height: 300px !important;
            > div {
              width: 100%;
              height: 300px !important;
              > svg {
                width: 100%;
                height: 300px !important;
              }
            }
          }
        }
      }
    }
  }
}
