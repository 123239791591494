.usage {
  .top-fifty-crops {
    padding: 20px 20px 15px 20px;
    border-radius: 4px;
    border: solid 1px $very-light-blue;
    background-color: $pale-grey;
    margin-top: 0;
    margin-bottom: 20px;
    .title-container {
      text-align: center;
      margin-bottom: 10px;
      h3 {
        font-size: 15px;
        font-weight: $font-weight-medium;
        color: $brownish-grey;
        text-transform: uppercase;
      }
      span {
        font-size: 10px;
        color: $warm-grey;
        margin-left: 5px;
      }
    }
    .button-holder {
      text-align: center;
      padding: 8px 0 0 0;
      width: 100%;
      .viewmore {
        color: $cadet-blue;
        text-transform: uppercase;
        font-weight: $font-weight-medium;
        text-decoration: none;
        position: relative;
        background: transparent;
        font-size: 13px;
        border: none;
        padding: 10px 0 0 0;
        &:hover {
          opacity: 0.6;
        }

        &::before {
          content: '';
          position: absolute;
          width: 14px;
          height: 14px;
          @include icons;
          background-position: -93px -16px;
          left: -20px;
          top: 12px;
        }
      }
    }
    .result-table {
      .no-data {
        text-align: center;

        padding: 10px 0;
        p {
          font-size: 15px;
          margin-bottom: 0;
          color: $brownish-grey;
        }
      }
      .result-table-row {
        background-color: $white !important;
        box-shadow: inset 0 -1px 0 0 $very-light-blue;
        a {
          > div {
            padding-left: 15px;
            &:nth-of-type(1) {
              flex: 0 0 143px;
            }
            &:nth-of-type(2) {
              flex: 0 0 122px;
            }
            &:nth-of-type(3) {
              flex: 0 0 115px;
            }
            &:nth-of-type(4) {
              flex: 0 0 115px;
            }
            &:nth-of-type(5) {
              flex: 0 0 129px;
            }
            &:nth-of-type(6) {
              flex: 0 0 166px;
            }
          }
        }
        &.titles {
          > div {
            > div {
              text-transform: none;
              padding-left: 15px;
              &::after {
                display: none;
              }
              &:nth-of-type(1) {
                flex: 0 0 143px;
              }
              &:nth-of-type(2) {
                flex: 0 0 122px;
              }
              &:nth-of-type(3) {
                flex: 0 0 115px;
              }
              &:nth-of-type(4) {
                flex: 0 0 115px;
              }
              &:nth-of-type(5) {
                flex: 0 0 129px;
              }
              &:nth-of-type(6) {
                flex: 0 0 166px;
              }
            }
          }
        }
      }
    }
  }
  table {
  }
  .map-container {
    padding: 20px;
    border-radius: 4px;
    border: solid 1px $very-light-blue;
    background-color: $pale-grey;
    margin-top: 0;
    margin-bottom: 20px;
    .title-container {
      text-align: center;
      margin-bottom: 30px;
      h3 {
        font-size: 15px;
        font-weight: $font-weight-medium;
        color: $brownish-grey;
        text-transform: uppercase;
      }
      span {
        font-size: 10px;
        color: $warm-grey;
        margin-left: 5px;
      }
    }
    .map-image-container {
      text-align: center;
      img {
        width: 100%;
      }
      .year {
        font-size: 13px;
        width: 100px;
        height: 25px;
        background-color: $brownish-grey;
        text-align: center;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: $font-weight-medium;
        margin: 0 auto;
      }
    }
  }
  .table-container {
    &.related-chems {
      table {
        table-layout: fixed;
        margin-bottom: 0;
        th {
          &:last-of-type {
            width: 151px;
            .tooltip-icon {
              margin-left: 5px;
              margin-right: 5px;
              &::after {
                background-position: -130px -26px;
              }
            }
          }
        }
      }
      .button-holder {
        text-align: center;
        padding: 5px 0 0 0;
        width: 100%;
        .viewmore {
          color: $cadet-blue;
          text-transform: uppercase;
          font-weight: $font-weight-medium;
          text-decoration: none;
          position: relative;
          background: transparent;
          font-size: 13px;
          border: none;
          padding: 10px 0 0 0;
          &:hover {
            opacity: 0.6;
          }

          &::before {
            content: '';
            position: absolute;
            width: 14px;
            height: 14px;
            @include icons;
            background-position: -93px -16px;
            left: -20px;
            top: 12px;
          }
        }
      }
    }
    border: solid 1px $very-light-blue;
    background-color: $pale-grey;
    padding: 20px 20px 9px;
    margin-bottom: 20px;
    .title-container {
      display: flex;
      align-items: center;
      h3 {
        font-size: 15px;
        text-transform: uppercase;
        font-weight: $font-weight-medium;
      }
      span {
        &.count {
          font-size: 10px;
          color: $warm-grey;
          margin-left: 5px;
        }
      }
    }
    table {
      &.related-chemicals {
        table-layout: fixed;
        tbody {
          tr {
            td {
              width: calc(100% / 6);
              border-bottom: solid 1px $very-light-blue;
              border-left: none;
              border-right: none;
              border-top: none;
              width: auto;
              word-break: break-word;
            }
          }
        }
      }
      &.heading-table {
        margin-top: 10px;
        //table-layout: fixed;
        thead {
          tr {
            background-color: $dark-navy-blue;
            th {
              font-size: 13px;
              color: $white;
              font-weight: $font-weight-medium;
              border: none;
              vertical-align: top;
              //   &:nth-of-type(1) {
              //     width: 143px;
              //   }
              //   &:nth-of-type(2) {
              //     width: 122px;
              //   }
              //   &:nth-of-type(3) {
              //     width: 115px;
              //   }
              //   &:nth-of-type(4) {
              //     width: 115px;
              //   }
              //   &:nth-of-type(5) {
              //     width: 129px;
              //   }
              //   &:nth-of-type(6) {
              //     width: 161px;
              //   }
            }
          }
        }
        tbody {
          tr {
            background-color: $white;
            td {
              padding: 15px;
              width: auto;
              //   &:nth-of-type(1) {
              //     width: 143px;
              //   }
              //   &:nth-of-type(2) {
              //     width: 122px;
              //   }
              //   &:nth-of-type(3) {
              //     width: 115px;
              //   }
              //   &:nth-of-type(4) {
              //     width: 115px;
              //   }
              //   &:nth-of-type(5) {
              //     width: 129px;
              //   }
              //   &:nth-of-type(6) {
              //     width: 161px;
              //   }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .usage {
    .top-fifty-crops {
      .result-table {
        width: 786px;
      }
    }
    .table-container {
      table {
        &.related-chemicals {
          width: 1000px;
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .usage {
    .top-fifty-crops {
      width: 100%;
      overflow-y: auto;
      .result-table {
        width: 788px;
      }
      .title-container {
        display: flex;
        align-items: center;
        text-align: left;
      }
    }
    .map-container {
      .title-container {
        text-align: left;
        margin-bottom: 30px;
      }
      .map-image-container {
        text-align: center;
        position: relative;
        img {
          width: 676px;
          height: 280px;
        }
        .year {
          position: absolute;
          right: 0;
          right: 54px;
          bottom: 3px;
          font-size: 15px;
          width: 135px;
          height: 34px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .usage {
    .top-fifty-crops {
      .result-table {
        // width: 788px;
      }
    }
  }
}
