.tooltip {
  &.show {
    opacity: 1;
    padding: 10px;
    border-radius: 4px;
    .tooltip-inner {
      background-color: #ffffff;
      box-shadow: 0 0 20px 5px rgba(47, 47, 47, 0.2);
      font-size: 12px;
      line-height: 1.5;
      color: $black-grey;
      text-align: left;
      max-width: 320px;
      opacity: 1;
      font-family: $primary-font;
      letter-spacing: normal;
      a {
        color: $cadet-blue;
        font-weight: $font-weight-medium;
        &:hover {
          text-decoration: none;
          opacity: 0.7;
        }
      }
    }
    .arrow {
      top: 4px;
      &::before {
        border-bottom-color: $white;
      }
    }
  }
}

.tab-content {
  .tooltip-holder {
    display: inline-block;
    div {
      &.tooltip-icon {
        width: 14px;
        height: 14px;
        margin-left: 10px;
        &::after {
          @include icons;
          content: '';
          width: 14px;
          height: 14px;
          background-position: -111px -42px;
          position: absolute;
        }
      }
    }
  }
}

.search-result-table-container {
  .result-table-row {
    &.titles {
      .tooltip-holder {
        display: inline-block;
        div {
          &.tooltip-icon {
            width: 14px;
            height: 14px;
            margin-left: 10px;
            &::after {
              @include icons;
              content: '';
              width: 14px;
              height: 14px;
              background-position: -130px -26px;
              position: absolute;
              top: 17px;
            }
          }
        }
      }
    }
  }
}
