.california-tab-content {
  .overview-content {
    margin-bottom: 12px;
    margin-left: 12px;
    margin-right: 12px;
    .content-holder {
      display: flex;
      justify-content: space-between;
      padding: 8px 18px;
      border: solid 1px $very-light-blue;
      background-color: $pale-grey;
      border-radius: 4px;
      h4 {
        font-size: 15px;
        font-weight: $font-weight-medium;
        color: $brownish-grey;
        margin-bottom: 21px;
      }
      p {
        font-size: 13px;
        color: $brownish-grey;
        margin-bottom: 13px;
        line-height: 1.3;
        strong {
          font-weight: $font-weight-medium;
        }
        a {
          color: $cadet-blue;
          &:hover {
            text-decoration: none;
            color: $brownish-grey;
          }
        }
      }
      .map {
        width: 200px;
        height: 260px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .california-tab-content {
    .overview-content {
      .content-holder {
        .map {
          margin-right: 150px;
        }
      }
    }
  }
}
