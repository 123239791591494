.search-container {
  padding: 38px 0;
  //background-image: url($california-search-bg);
  background-image: url('../../../images/background/california-search-container.png');
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  p {
    &.error {
      display: none;
      position: absolute;
      text-align: center;
      font-size: 12px;
      color: $salmon;
      font-weight: $font-weight-medium;
      left: 0;
      right: 0;
      //display: none;
      top: 30px;
    }
  }
  h2 {
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    color: $white;
    margin-bottom: 55px;
  }
  .selection-wrapper {
    display: block;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    i {
      display: none;
      &.plus {
        width: 14px;
        height: 14px;
        margin: 0 8px;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          width: 14px;
          height: 14px;
          @include icons;
          background-position: -130px -42px;
        }
      }
    }
    .search-button {
      a {
        width: 100%;
        height: 60px;
        border-radius: 38.5px;
        background-color: $white;
        display: block;
        position: relative;
        &::after {
          content: '';
          @include icons;
          background-position: -199px -32px;
          position: absolute;
          width: 26px;
          height: 26px;
          left: 0;
          right: 0;
          margin: auto auto;
          top: 0;
          bottom: 0;
        }
        &:hover {
          background-color: $cadet-blue;
          &::after {
            background-position: -226px -32px;
          }
        }
      }
      &.disable {
        opacity: 0.7;
        pointer-events: none;
        cursor: not-allowed;
      }
    }
    .dropdown-holder {
      margin-bottom: 10px;
      &:nth-of-type(1) {
        .css-2b097c-container {
          .css-yk16xz-control,
          .css-1pahdxg-control {
            > div {
              > div {
                &:nth-of-type(1) {
                  &::after {
                    right: 60px;
                  }
                }
              }
            }
          }
        }
      }
      &:nth-of-type(2) {
        .css-2b097c-container {
          .css-yk16xz-control,
          .css-1pahdxg-control {
            > div {
              > div {
                &:nth-of-type(1) {
                  &::after {
                    right: 53px;
                  }
                }
              }
            }
          }
        }
      }
      &:nth-of-type(3) {
        .css-2b097c-container {
          .css-yk16xz-control,
          .css-1pahdxg-control {
            > div {
              > div {
                &:nth-of-type(1) {
                  &::after {
                    right: 36px;
                  }
                }
              }
            }
          }
        }
      }
      .css-2b097c-container {
        .css-26l3qy-menu {
          margin: 2px 0;
          width: 80%;
          left: 10%;
          font-family: $primary-font;
          font-size: 16px;
          color: $warm-grey;
          font-weight: $font-weight-light;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
          > div > div > div > div {
            padding: 5px 30px;
            font-size: 16px;
            font-weight: $font-weight-light;
            color: $black-grey;
            background-color: white;
            &:hover {
              background-color: #4d7996;
              color: $white;
            }
            &.css-9gakcf-option {
              background-color: $cadet-blue;
              color: $white;
            }
          }
        }
        .css-yk16xz-control,
        .css-1pahdxg-control {
          width: 340px;
          height: 60px;
          border-radius: 38.5px;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
          background-color: $white;
          border: none;
          &:hover {
            border: none;
          }
          > div {
            padding: 0 20px 0 35px;
            text-align: center;
            position: relative;
            &:nth-of-type(2) {
              display: none !important;
            }
            > div {
              &:nth-of-type(1) {
                left: 25px;
                right: 35px;
                margin: 0 auto;
                font-family: $primary-font;
                font-size: 16px;
                color: $cadet-blue;
                font-weight: $font-weight-light;
                &.css-1wa3eu0-placeholder {
                  opacity: 0.5;
                  color: $warm-grey;
                }
              }
            }
            .css-1uccc91-singleValue {
              left: 0;
              right: 0;
              margin: 0 auto;
              font-family: $primary-font;
              font-size: 16px;
              font-weight: $font-weight-light;
            }
          }
          &::after {
            content: '';
            @include icons;
            position: absolute;
            right: 35px;
            top: 27px;
            background-position: -150px -50px;
            width: 8px;
            height: 5px;
          }
          //   .css-1hb7zxy-IndicatorsContainer {
          //     display: none !important;
          //   }
        }
      }
    }
  }
  .shortcut-wrapper {
    h3 {
      text-align: center;
      font-size: 12px;
      font-weight: $font-weight-medium;
      color: $white;
      margin-bottom: 20px;
    }
    .link-wrapper {
      a {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
        @include button-transparent(
          $padding: 10px 30px 10px 30px,
          $color: transparent
        );
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .search-container {
    .shortcut-wrapper {
      h3 {
        text-align: center;
        font-size: 12px;
        font-weight: $font-weight-medium;
        color: $white;
        margin-bottom: 20px;
      }
      .link-wrapper {
        text-align: center;
        a {
          width: 372px;
          margin: 0 auto 10px auto;
        }
      }
    }
    .selection-wrapper {
      .search-button {
        a {
          width: 340px;
          margin: 0 auto;
        }
      }
      .dropdown-holder {
        .css-2b097c-container {
          .css-yk16xz-control,
          .css-1pahdxg-control {
            width: 340px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .search-container {
    .selection-wrapper {
      display: flex;
      margin-bottom: 33px;
      i {
        display: block;
      }
      .search-button {
        margin-left: 30px;
        a {
          width: 60px;
          margin: initial;
        }
      }
      .dropdown-holder {
        margin-bottom: 0;
        &:nth-of-type(1) {
          .css-2b097c-container {
            .css-yk16xz-control,
            .css-1pahdxg-control {
              > div {
                > div {
                  &:nth-of-type(1) {
                    &::after {
                      right: 30px;
                    }
                  }
                }
              }
            }
          }
        }
        &:nth-of-type(2) {
          .css-2b097c-container {
            .css-yk16xz-control,
            .css-1pahdxg-control {
              > div {
                > div {
                  &:nth-of-type(1) {
                    &::after {
                      right: 30px;
                    }
                  }
                }
              }
            }
          }
        }
        &:nth-of-type(3) {
          .css-2b097c-container {
            .css-yk16xz-control,
            .css-1pahdxg-control {
              > div {
                > div {
                  &:nth-of-type(1) {
                    &::after {
                      right: 20px;
                    }
                  }
                }
              }
            }
          }
        }
        .css-2b097c-container {
          .css-26l3qy-menu {
            font-family: $primary-font;
            font-size: 16px;
            color: $warm-grey;
            font-weight: $font-weight-light;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
            .css-4ljt47-MenuList {
              padding-top: 20px;
            }
          }
          .css-yk16xz-control,
          .css-1pahdxg-control {
            width: 270px;
            margin: initial;
            > div {
              > div {
                font-size: 14px !important;
              }
            }
          }
        }
      }
    }
    .shortcut-wrapper {
      //border-top: solid 1px rgba(255, 255, 255, 0.3);
      text-align: center;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 44%;
        height: 1px;
        left: 0;
        background-color: $white;
        opacity: 0.3;
        top: 12px;
      }
      &::before {
        content: '';
        position: absolute;
        width: 44%;
        height: 1px;
        right: 0;
        background-color: $white;
        opacity: 0.3;
        top: 12px;
      }
      h3 {
        margin-bottom: 15px;
        display: inline-block;
        padding: 0 10px;
        background-color: transparent;
      }
      .link-wrapper {
        display: flex;
        justify-content: center;
        padding-left: 80px;
        a {
          display: inline-block;
          width: auto;
          text-align: initial;
          margin: 0 40px 0 0;
          @include button-transparent(
            $padding: 10px 33px 10px 33px,
            $color: transparent
          );
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .search-container {
    .selection-wrapper {
      .dropdown-holder {
        &:nth-of-type(1) {
          .css-2b097c-container {
            .css-yk16xz-control,
            .css-1pahdxg-control {
              > div {
                > div {
                  &:nth-of-type(1) {
                    &::after {
                      right: 60px;
                    }
                  }
                }
              }
            }
          }
        }
        &:nth-of-type(2) {
          .css-2b097c-container {
            .css-yk16xz-control,
            .css-1pahdxg-control {
              > div {
                > div {
                  &:nth-of-type(1) {
                    &::after {
                      right: 53px;
                    }
                  }
                }
              }
            }
          }
        }
        &:nth-of-type(3) {
          .css-2b097c-container {
            .css-yk16xz-control,
            .css-1pahdxg-control {
              > div {
                > div {
                  &:nth-of-type(1) {
                    &::after {
                      right: 36px;
                    }
                  }
                }
              }
            }
          }
        }
        .css-2b097c-container {
          .css-yk16xz-control,
          .css-1pahdxg-control {
            width: 340px;
            > div {
              > div {
                font-size: 16px !important;
                // width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
